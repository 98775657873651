import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "../auth/react-auth0-wrapper";
import { CenterContainer, Footer, Header } from ".";
import Sidebar from "./Sidebar";

var DefaultLayout = function DefaultLayout(_ref) {
  var children = _ref.children,
      hideLogin = _ref.hideLogin,
      _ref$hideOverflow = _ref.hideOverflow,
      hideOverflow = _ref$hideOverflow === void 0 ? false : _ref$hideOverflow,
      centerStyle = _ref.centerStyle,
      _ref$hideSidebar = _ref.hideSidebar,
      hideSidebar = _ref$hideSidebar === void 0 ? false : _ref$hideSidebar;

  // still might try to get messages to work eventually, but setting a referrer for now
  // const tokenExpirationDate =
  //   localStorage.getItem("tokenExpirationDate") ?? "0";
  // const tokenExpired = new Date(parseInt(tokenExpirationDate)) < new Date();
  // const { physician, selectedInstitution } = useAuth0();
  var _useAuth = useAuth0(),
      selectedInstitution = _useAuth.selectedInstitution;

  var _useState = useState(hideSidebar),
      _useState2 = _slicedToArray(_useState, 2),
      hiddenSidebar = _useState2[0],
      setHiddenSidebar = _useState2[1];

  var _useState3 = useState(hideSidebar),
      _useState4 = _slicedToArray(_useState3, 2),
      hideHamburger = _useState4[0],
      setHideHamburger = _useState4[1];

  var authorized = Boolean(sessionStorage.getItem("authToken")) || sessionStorage.getItem("from-orders") === "yes";

  var windowNotWideEnough = function windowNotWideEnough() {
    var _window = window,
        innerWidth = _window.innerWidth;
    return innerWidth < 1650;
  };

  var handleResize = function handleResize() {
    if (authorized) {
      if (windowNotWideEnough()) {
        setHiddenSidebar(true);
        setHideHamburger(false);
      } else {
        setHiddenSidebar(false);
        setHideHamburger(true);
      }
    }
  };

  useEffect(function () {
    window.addEventListener("resize", handleResize);
    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(function () {
    setHiddenSidebar(!authorized || windowNotWideEnough());
    setHideHamburger(!authorized || !windowNotWideEnough());
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Header, {
    hideLogin: hideLogin,
    showHamburger: !hideHamburger,
    institutionCode: selectedInstitution && selectedInstitution.institutionCode
  }), React.createElement(Sidebar, {
    hide: hiddenSidebar
  }), React.createElement(CenterContainer, {
    style: Object.assign({
      minHeight: "calc(100vh - 3em)",
      paddingBottom: "2em"
    }, centerStyle)
  }, React.createElement("div", {
    style: {
      paddingTop: "4em",
      paddingBottom: "1em",
      height: "100%",
      overflowX: hideOverflow ? "hidden" : "visible"
    }
  }, children)), React.createElement(Footer, null));
};

export default DefaultLayout;