import React from "react";
import { SectionHeader, SectionInfo } from ".";

var ConsentInformation = function ConsentInformation(_ref) {
  var title = _ref.title,
      description = _ref.description;
  return React.createElement("div", {
    style: {
      breakInside: "avoid"
    }
  }, title && React.createElement(SectionHeader, {
    style: {
      marginTop: "1.5em"
    }
  }, title), description && React.createElement("div", {
    style: {
      marginBottom: "0.5em"
    }
  }, React.createElement(SectionInfo, null, description)));
};

export default ConsentInformation;