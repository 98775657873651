import React from "react";
import { withStyles, alpha, createStyles, InputBase, Select, InputLabel, makeStyles } from "@material-ui/core";
import * as colors from "../theme/colors";
var StyledInput = withStyles(function (theme) {
  return createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
        color: "#A0A0A0"
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #DADADA",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        boxShadow: "".concat(alpha(theme.palette.primary.main, 0.25), " 0 0 0 0.2rem"),
        borderColor: theme.palette.primary.main
      }
    }
  });
})(InputBase);
var useStyles = makeStyles({
  label: {
    color: colors.sleepyGrey,
    fontWeight: "bold",
    paddingLeft: 15
  }
});

var CustomSelect = function CustomSelect(props) {
  var classes = useStyles();
  return React.createElement(React.Fragment, null, props.label && React.createElement(InputLabel, {
    shrink: true,
    className: classes.label,
    style: props.error ? {
      color: "red"
    } : {}
  }, props.required ? props.label + " *" : props.label), React.createElement(Select, Object.assign({
    native: true
  }, props, {
    input: React.createElement(StyledInput, null)
  })));
};

export default CustomSelect;