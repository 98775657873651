import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { colors, fonts } from "../theme";
var defaultTheme = createTheme();
var useStyles = makeStyles(createStyles({
  label: _defineProperty({
    fontFamily: fonts.fonts,
    color: colors.santaCharcoal,
    fontSize: "0.85em",
    paddingBottom: 5,
    paddingLeft: 10,
    textAlign: "left"
  }, defaultTheme.breakpoints.down("md"), {
    fontSize: ".75em"
  }),
  value: _defineProperty({
    fontFamily: fonts.fonts,
    color: colors.sleepyGrey,
    fontSize: fonts.sizes.xxs,
    fontWeight: "bold",
    paddingLeft: 10,
    textAlign: "left",
    wordWrap: "break-word"
  }, defaultTheme.breakpoints.down("md"), {
    fontSize: fonts.sizes.xxs
  })
}));

var FieldAndValue = function FieldAndValue(_ref) {
  var field = _ref.field,
      value = _ref.value,
      fieldStyle = _ref.fieldStyle,
      valueStyle = _ref.valueStyle;
  var classes = useStyles();
  return React.createElement(React.Fragment, null, field && React.createElement("div", {
    className: classes.label,
    style: fieldStyle
  }, field), value && React.createElement("div", {
    className: classes.value,
    style: valueStyle
  }, value));
};

export default FieldAndValue;