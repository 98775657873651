import React from "react";
import { colors, fonts } from "../../theme";
import GlobalLink from "../GlobalLink";
import { createStyles, withStyles, Divider } from "@material-ui/core";
import { Publish } from "@material-ui/icons";

var styles = function styles(theme) {
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "1px",
      fontSize: "12px",
      color: colors.lightGrey,
      backgroundColor: colors.seaShell,
      minWidth: "155px",
      textAlign: "left",
      maxWidth: "265px",
      wordWrap: "break-word",
      overflow: "hidden",
      overflowWrap: "break-word"
    },
    header: {
      fontWeight: 700,
      borderBottom: "3px solid ".concat(colors.seaShell),
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      paddingLeft: theme.spacing(1),
      paddingTop: "5px",
      color: colors.lightGrey
    },
    content: {
      padding: "5px 0",
      "&:hover": {
        fontWeight: 800
      }
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      fontSize: fonts.sizes.xxs,
      fontWeight: 400,
      color: colors.lightGrey,
      backgroundColor: colors.white,
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    },
    testSectionHeader: {
      fontWeight: 500
    },
    testSection: {
      padding: "5px 0",
      "&:hover": {
        fontWeight: 800
      },
      marginLeft: theme.spacing(1)
    },
    manifestDivider: {
      height: "3px"
    },
    iconAndLabel: {
      display: "flex",
      alignItems: "center"
    },
    iconText: {
      marginLeft: theme.spacing(1)
    },
    divider: {
      height: "0px",
      border: "1px solid rgba(241, 241, 241, 1)",
      width: "265px",
      marginLeft: -theme.spacing(1),
      marginRight: -theme.spacing(1)
    }
  });
};

var getHeader = function getHeader(header, headerUrl) {
  return headerUrl ? React.createElement("a", {
    href: headerUrl
  }, header) : header;
};

var Overlay = function Overlay(_ref) {
  var tests = _ref.tests,
      header = _ref.header,
      headerUrl = _ref.headerUrl,
      urlPrefix = _ref.urlPrefix,
      institutionCode = _ref.institutionCode,
      classes = _ref.classes;

  var renderTestSection = function renderTestSection(testInfo) {
    var url = function url(test) {
      var result = "".concat(urlPrefix).concat(test.displayTestCode ? "".concat(test.displayTestCode, "&ctc=").concat(test.code) : test.code);
      var createOrderAddOnTestCodes = window.appConfig.createOrderAddOnTests.find(function (x) {
        return x.testCode === test.code && institutionCode && x.hospitalCodes.includes(institutionCode);
      });

      if (createOrderAddOnTestCodes && createOrderAddOnTestCodes.addOnTests.length > 0) {
        result = "".concat(result, ",test=").concat(createOrderAddOnTestCodes.addOnTests.join(",test="));
      }

      return "".concat(result, "&new-cart=true");
    };

    return React.createElement(React.Fragment, null, testInfo.testType && React.createElement("text", {
      className: classes.testSectionHeader
    }, testInfo.testType), testInfo.tests.map(function (tc) {
      return React.createElement(GlobalLink, {
        className: classes.testSection,
        to: url(tc),
        key: "".concat(tc.code)
      }, tc.testName);
    }));
  }; // this will need to be revisited if we include customers that will want VOB, but not want to upload a manifest for VOB


  var canUploadManifest = Boolean(window.appConfig.features.isCUHKManifestUploadEnabled) && institutionCode === "CUHK";
  return React.createElement("div", {
    className: classes.container
  }, React.createElement("div", {
    className: classes.header
  }, getHeader(header, headerUrl)), React.createElement("div", {
    className: classes.innerContainer
  }, canUploadManifest && React.createElement(React.Fragment, null, React.createElement(GlobalLink, {
    className: classes.testSection,
    to: "/create/manifest",
    key: "manifest-upload",
    tooltipTitle: "Click to create orders by CSV"
  }, React.createElement("div", {
    className: classes.iconAndLabel
  }, React.createElement(Publish, null), React.createElement("span", {
    className: classes.iconText
  }, "Upload Manifest"))), React.createElement(Divider, {
    className: classes.manifestDivider
  })), tests.map(function (test) {
    return renderTestSection(test);
  })));
};

export default withStyles(styles)(Overlay);