import "react-app-polyfill/ie11";
import "webcrypto-shim";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@bg/shared/lib/auth/react-auth0-wrapper";
import * as serviceWorker from "./serviceWorker";

const onRedirectCallback = (appState: any) => {
  document.location.replace(appState.targetUrl.href);
};

ReactDOM.render(
  <Auth0Provider
    domain={window.appConfig.auth0Config.domain}
    client_id={window.appConfig.auth0Config.clientID}
    audience={window.appConfig.auth0Config.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    leeway={window.appConfig.auth0Config.leeway}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
