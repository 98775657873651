import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography } from "@material-ui/core";
import { colors, fonts } from "../theme";
var defaultTheme = createTheme();
var useStyles = makeStyles(createStyles({
  label: _defineProperty({
    fontFamily: fonts.fonts,
    color: colors.deepSeaBlue,
    fontSize: fonts.sizes.xl
  }, defaultTheme.breakpoints.down("md"), {
    fontSize: fonts.sizes.xxs
  })
}));

var Info = function Info(_ref) {
  var children = _ref.children,
      color = _ref.color,
      fontSize = _ref.fontSize;
  var classes = useStyles();
  return React.createElement("div", {
    style: {
      wordWrap: "break-word"
    }
  }, React.createElement(Typography, {
    className: classes.label,
    style: {
      color: color,
      fontSize: fontSize
    }
  }, children));
};

export default Info;