import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button, Grid, createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
var useStyles = makeStyles(createStyles({
  smallIconContainer: {
    marginTop: "1.125em",
    justifyContent: "center"
  },
  dashboardCard: {
    background: "#FFFFFF",
    border: "1px solid rgba(199, 199, 199, 0.5)",
    boxSizing: "border-box",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "all .2s ease-in-out;",
    "&:hover": {
      background: "#FFFFFF",
      transform: "scale(1.1)",
      zIndex: 1
    },
    padding: "5px 5px",
    minWidth: "238px",
    minHeight: "73px",
    width: "100%"
  },
  smallIcon: {
    padding: "7px",
    height: "60px",
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1"
  },
  smallIconPic: {
    width: "35px",
    height: "35px"
  },
  smallButtonText: {
    textAlign: "inherit",
    verticalAlign: "middle",
    textTransform: "uppercase",
    color: "#284391",
    fontSize: "0.8em",
    fontWeight: 400,
    paddingLeft: "10px"
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));
var theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1620
    }
  }
});

var SecondaryCategories = function SecondaryCategories(_ref) {
  var secondaryCategories = _ref.secondaryCategories;
  var classes = useStyles();

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      secondaryCategoriesToDisplay = _useState2[0],
      setSecondaryCategoriesToDisplay = _useState2[1];

  var categories = secondaryCategories;
  useEffect(function () {
    var secondaryCategoriesToDisplay = [];
    var categoriesToDisplay = ["carrier", "rapid", "wgs", "wes", "cma", "mito", "confirmation", "ngs"];

    if (categories) {
      categories.filter(function (x) {
        return categoriesToDisplay.includes(x.name.toLowerCase());
      }).map(function (item) {
        var found = secondaryCategoriesToDisplay.find(function (x) {
          return x.name === item.name;
        });

        if (!found) {
          secondaryCategoriesToDisplay.push(item);
        }
      });
      setSecondaryCategoriesToDisplay(secondaryCategoriesToDisplay);
    }
  }, []);
  return React.createElement(ThemeProvider, {
    theme: theme
  }, React.createElement(Grid, {
    container: true,
    className: classes.smallIconContainer
  }, secondaryCategoriesToDisplay.map(function (x) {
    return React.createElement(Grid, {
      item: true,
      key: x.id,
      xl: 3,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
      className: classes.cardContainer
    }, React.createElement(Button, {
      key: x.id,
      href: "".concat(window.appConfig.catalogUrl, "/category/").concat(x.name.toLowerCase()),
      className: classes.dashboardCard
    }, React.createElement(Grid, {
      item: true,
      xs: 5,
      className: classes.smallIcon
    }, React.createElement("img", {
      className: classes.smallIconPic,
      src: "/images/" + x.name.toUpperCase() + ".svg"
    })), React.createElement(Grid, {
      item: true,
      xs: 6,
      className: classes.smallButtonText
    }, x.displayName)));
  })));
};

export default SecondaryCategories;