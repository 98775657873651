import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useLookups } from "./LookupProvider";
import CustomSelect from "../CustomSelect";
export var GetStateName = function GetStateName(stateId) {
  var state = useLookups().states.find(function (_ref) {
    var id = _ref.id;
    return id === stateId;
  });
  return state ? state.name : "";
};

var StatePicker = function StatePicker(props) {
  if (props.version === 2) {
    return React.createElement(FormControl, {
      fullWidth: props.fullWidth
    }, React.createElement(InputLabel, {
      style: props.error ? {
        color: "red"
      } : {}
    }, props.required ? "State *" : "State"), React.createElement(CustomSelect, Object.assign({}, props), React.createElement("option", null), useLookups().states.map(function (state, index) {
      return React.createElement("option", {
        key: index,
        value: state.id
      }, state.name);
    })));
  }

  return React.createElement(FormControl, {
    fullWidth: props.fullWidth
  }, React.createElement(InputLabel, {
    style: props.error ? {
      color: "red"
    } : {}
  }, props.required ? "State *" : "State"), React.createElement(Select, Object.assign({
    native: true
  }, props), React.createElement("option", null), useLookups().states.map(function (state, index) {
    return React.createElement("option", {
      key: index,
      value: state.id
    }, state.name);
  })));
};

export default StatePicker;