import { makeStyles, createStyles } from "@material-ui/core/styles";
import { fonts } from "../../theme";
var useStyles = makeStyles(function (theme) {
  return createStyles({
    loading: {
      width: "179px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#e5e5e5",
      minHeight: "250px"
    },
    container: {
      width: "179px",
      fontSize: fonts.sizes.xxxs,
      backgroundColor: "#e5e5e5"
    },
    header: {
      color: "#888888",
      fontWeight: 500,
      height: "25px",
      lineHeight: "25px",
      paddingLeft: "10px",
      boxShadow: "0px 2px 2px lightgrey"
    },
    accordionContainer: {
      padding: "0 ".concat(theme.spacing(1), "px")
    },
    accordion: {
      backgroundColor: "#f4f4f4",
      borderRadius: "4px",
      margin: "".concat(theme.spacing(1), "px 0  !important")
    },
    accordionSummary: {
      minHeight: "30px !important",
      height: "30px",
      fontWeight: 600,
      padding: "0 ".concat(theme.spacing(1), "px 0 ").concat(theme.spacing(2), "px"),
      lineHeight: "30px",
      "& > div": {
        alignItems: "center"
      },
      "& p": {
        marginRight: "8px"
      },
      "& span": {}
    },
    accordionDetails: {
      padding: 0,
      width: "100%",
      "& ul": {
        width: "100%"
      },
      "& li": {
        width: "100%",
        height: "43px",
        justifyContent: "space-between"
      }
    },
    list: {
      padding: 0
    }
  });
});
export default useStyles;