import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { colors, fonts } from "../theme";

var SectionHeader = function SectionHeader(props) {
  return React.createElement(Typography, Object.assign({}, props, {
    style: {
      color: colors.powderBlue,
      fontSize: fonts.sizes.xxs,
      fontFamily: fonts.fonts,
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    className: clsx(props.className)
  }), props.children);
};

export default SectionHeader;