import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { Button, createStyles } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { colors, fonts } from "../theme";
import { formatId } from "./formatters";

var styles = function styles(theme) {
  return createStyles({
    button: {
      "&:hover": {
        background: darken(colors.grey, 0.1)
      }
    },
    buttonLabel: Object.assign(Object.assign({}, fonts.fontStyles.panelFont), {
      display: "block",
      fontFamily: "'Roboto Condensed', 'Helvetica', 'Arial', sans-serif",
      fontSize: "2rem",
      textTransform: "capitalize",
      padding: "0 0.5em",
      textAlign: "left"
    }),
    container: {
      backgroundColor: colors.white,
      width: "100%"
    },
    panel: {
      overflow: "hidden",
      padding: "0.75em 1.75em"
    },
    expanded: {
      transition: "transform 150ms ease",
      transformOrigin: "top",
      transform: "scaleY(1)"
    },
    hidden: {
      maxHeight: 0,
      transition: "transform 150ms ease",
      transformOrigin: "top",
      transform: "scaleY(0)"
    },
    icon: {
      fontSize: "2rem",
      marginRight: "0.25em",
      float: "right",
      verticalAlign: "middle"
    },
    error: {
      border: "thin solid ".concat(colors.candyRed)
    }
  });
};

var CatalogExpansionPanel = forwardRef(function (props, ref) {
  var classes = props.classes,
      children = props.children,
      defaultExpanded = props.defaultExpanded,
      title = props.title,
      id = props.id,
      style = props.style,
      titleStyle = props.titleStyle,
      buttonStyle = props.buttonStyle,
      contentStyle = props.contentStyle,
      _props$renderArrow = props.renderArrow,
      renderArrow = _props$renderArrow === void 0 ? true : _props$renderArrow,
      _props$error = props.error,
      error = _props$error === void 0 ? false : _props$error;

  var _useState = useState(defaultExpanded !== undefined ? defaultExpanded : false),
      _useState2 = _slicedToArray(_useState, 2),
      expanded = _useState2[0],
      setExpanded = _useState2[1];

  useImperativeHandle(ref, function () {
    return {
      expand: function expand() {
        setExpanded(true);
      }
    };
  });
  return React.createElement("div", {
    className: classes.container,
    style: style
  }, React.createElement(Button, {
    id: id ? id : formatId(title),
    className: error ? classes.error : "",
    classes: {
      root: classes.button,
      label: classes.buttonLabel
    },
    fullWidth: true,
    onClick: function onClick() {
      return setExpanded(!expanded);
    },
    style: buttonStyle
  }, React.createElement("span", {
    style: titleStyle
  }, title), renderArrow && React.createElement("span", null, expanded ? React.createElement(KeyboardArrowDown, {
    className: classes.icon
  }) : React.createElement(KeyboardArrowUp, {
    className: classes.icon
  }))), React.createElement("div", {
    style: contentStyle,
    className: clsx(classes.panel, expanded ? classes.expanded : classes.hidden)
  }, expanded && children));
});
export default withStyles(styles)(CatalogExpansionPanel);