import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useState } from "react";
import { Collapse, Menu, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import CardTravelOutlinedIcon from "@material-ui/icons/CardTravelOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import useStyles from "./styles";
import { withRouter } from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssignmentReturnOutlinedIcon from "@material-ui/icons/AssignmentReturnOutlined";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";

var HeaderMenu = function HeaderMenu(_ref) {
  var onClose = _ref.onClose,
      anchorEl = _ref.anchorEl,
      open = _ref.open,
      history = _ref.history;

  var _useState = useState({
    kits: true,
    orders: true
  }),
      _useState2 = _slicedToArray(_useState, 2),
      collapse = _useState2[0],
      setCollapse = _useState2[1];

  var classes = useStyles();
  var inCatalog = location.href.toLowerCase().includes(window.appConfig.catalogUrl);

  var onClickLink = function onClickLink(url) {
    onClose();
    inCatalog ? window.location.assign(window.appConfig.ordersUrl + url) : history.push(url);
  };

  return React.createElement(Menu, {
    id: "profile-menu",
    anchorEl: anchorEl,
    open: open,
    onClose: onClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    PaperProps: {
      style: {
        width: 230
      }
    }
  }, React.createElement(List, null, React.createElement(ListItem, {
    id: "headerMenu-dashboard-test-catalog",
    button: true,
    onClick: function onClick() {
      return window.location.assign(window.appConfig.catalogUrl);
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(HomeOutlinedIcon, null)), React.createElement(ListItemText, null, "Test Catalog")), React.createElement(ListItem, {
    id: "headerMenu-dashboard",
    button: true,
    onClick: function onClick() {
      return onClickLink("/dashboard");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(DashboardOutlinedIcon, null)), React.createElement(ListItemText, null, "Dashboard")), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        orders: !collapse.orders
      }));
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentOutlinedIcon, null)), React.createElement(ListItemText, {
    primary: "Orders"
  }), collapse.orders ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.orders,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(List, {
    disablePadding: true
  }, React.createElement(ListItem, {
    id: "headerMenu-dashboard-search",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(FindInPageOutlinedIcon, null)), React.createElement(ListItemText, null, " Search")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-en-route",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=en-route&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(LocalShippingOutlinedIcon, null)), React.createElement(ListItemText, null, "En Route")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-accessioned",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=accessioned&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentTurnedInOutlinedIcon, null)), React.createElement(ListItemText, null, "Accessioned")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-in-process",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=in-process&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement("img", {
    alt: "in-process",
    width: 24,
    height: 24,
    src: "/images/in-process-icon.svg"
  })), React.createElement(ListItemText, null, "In Process")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-completed",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=reported&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentReturnOutlinedIcon, null)), React.createElement(ListItemText, null, "Reported")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-pending-submission",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=pending-submission&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentLateOutlinedIcon, null)), React.createElement(ListItemText, null, "Pending Submission")))), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        kits: !collapse.kits
      }));
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(CardTravelOutlinedIcon, null)), React.createElement(ListItemText, {
    primary: "Kits"
  }), collapse.kits ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.kits,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(List, {
    disablePadding: true
  }, React.createElement(ListItem, {
    id: "headerMenu-dashboard-kits",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/kits");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(SearchOutlinedIcon, null)), React.createElement(ListItemText, null, "Search"))))));
};

export default withRouter(HeaderMenu);