import { GetInstitutionCodeToSearch } from "../utils/PhysicianHelper";
import config from "./config";
export var getAccessToken = function getAccessToken() {
  return sessionStorage.getItem("authToken") || "";
};
export var getUserEmail = function getUserEmail() {
  return sessionStorage.getItem("userEmail") || "";
};
export var storedInstitutionCodes = function storedInstitutionCodes() {
  return sessionStorage.getItem("institutionCodes") || "";
};

var getInstitutionCodes = function getInstitutionCodes() {
  var codes = storedInstitutionCodes();
  var codeToSearch = GetInstitutionCodeToSearch();

  if (codeToSearch && !codes.includes(codeToSearch)) {
    codes += ",".concat(codeToSearch);
  }

  return codes;
};

export var getApiHeader = function getApiHeader() {
  return {
    baseURL: config.apiUrl,
    headers: {
      Authorization: "Bearer ".concat(getAccessToken()),
      InstitutionCodes: getInstitutionCodes()
    }
  };
};
export var getApiHeaderWithContentType = function getApiHeaderWithContentType(contentType) {
  return {
    baseURL: config.apiUrl,
    headers: {
      Authorization: "Bearer ".concat(getAccessToken()),
      "Content-Type": contentType,
      InstitutionCodes: getInstitutionCodes()
    }
  };
};
export default {
  getAccessToken: getAccessToken,
  getUserEmail: getUserEmail,
  getApiHeader: getApiHeader,
  getApiHeaderWithContentType: getApiHeaderWithContentType
};