import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import { colors } from "../../theme";
export default function SearchIconButton(props) {
  return React.createElement("div", {
    style: {
      display: "inline-block"
    }
  }, React.createElement(Fab, Object.assign({
    id: "search",
    style: {
      backgroundColor: colors.powderBlue,
      color: colors.white,
      verticalAlign: "middle",
      boxShadow: "none"
    },
    size: "medium",
    "aria-label": "Search"
  }, props), React.createElement(SearchIcon, null)));
}