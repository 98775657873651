import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useLookups } from "./LookupProvider";
import CustomSelect from "../CustomSelect";

var CountryPicker = function CountryPicker(props) {
  var value = props.value || "";

  if (props.version === 2) {
    return React.createElement(FormControl, {
      fullWidth: props.fullWidth
    }, React.createElement(CustomSelect, Object.assign({}, props, {
      label: "Country",
      fullWidth: true,
      value: value
    }), React.createElement("option", null), useLookups().countries.map(function (country, index) {
      return React.createElement("option", {
        key: index,
        value: country.id
      }, country.name);
    })));
  }

  return React.createElement(FormControl, {
    fullWidth: props.fullWidth
  }, React.createElement(InputLabel, {
    style: props.error ? {
      color: "red"
    } : {}
  }, props.required ? "Country *" : "Country"), React.createElement(Select, Object.assign({
    native: true
  }, props, {
    value: value
  }), React.createElement("option", null), useLookups().countries.map(function (country, index) {
    return React.createElement("option", {
      key: index,
      value: country.id
    }, country.name);
  })));
};

export default CountryPicker;