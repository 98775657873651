import React from "react";
import { Dialog, DialogContent, DialogTitle, CircularProgress } from "@material-ui/core";

var ProgressDialog = function ProgressDialog(_ref) {
  var openDialog = _ref.openDialog,
      dialogTitle = _ref.dialogTitle;
  return React.createElement(Dialog, {
    open: openDialog ? openDialog : false,
    "aria-labelledby": "progress-dialog-title",
    "aria-describedby": "progress-dialog-description"
  }, React.createElement(DialogTitle, {
    id: "progress-dialog-title",
    style: {
      textAlign: "center"
    }
  }, dialogTitle || ""), React.createElement(DialogContent, {
    style: {
      textAlign: "center",
      height: "5em",
      minWidth: "10em"
    }
  }, React.createElement(CircularProgress, null)));
};

export default ProgressDialog;