import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { AppBar, Badge, Button, Hidden, IconButton, InputAdornment, OutlinedInput, Slide, Toolbar } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { ShoppingCartOutlined } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { HeaderMenu } from "../../HeaderMenu";
import MenuIcon from "@material-ui/icons/Menu";
import { colors } from '../../../theme';
import CompactMarketingMenu from './CompactMarketingMenu';
import MarketingMenu from './MarketingMenu';
var medSizeScreen = 960;
var smallSizeScreen = 600;

var styles = function styles() {
  return createStyles({
    banner: {
      backgroundColor: colors.white,
      height: 75,
      opacity: 0.9
    },
    mobileBanner: {
      backgroundColor: colors.white,
      height: 50,
      opacity: 0.9
    },
    logoDiv: {
      flexGrow: 1,
      display: "flex",
      marginTop: "0.5rem",
      marginLeft: "0.75rem"
    },
    logo: {
      display: "inline-block",
      marginLeft: 24,
      height: 20,
      textAlign: "center"
    },
    mobileLogo: {
      height: "27px",
      width: "80px"
    },
    root: {
      display: "flex",
      color: colors.royalBlue,
      flexWrap: "wrap",
      minWidth: 300,
      width: "fit-content"
    },
    searchBar: {
      borderRadius: "30px",
      border: "none"
    },
    cartBadge: {
      backgroundColor: colors.skyBlue,
      color: colors.deepSeaBlue,
      fontFamily: "Roboto Condensed",
      fontWeight: "bold"
    },
    disabledCartIcon: {
      color: colors.deepSeaBlue
    },
    searchDivButton: {
      height: "100%",
      width: "40px"
    },
    searchButton: {
      height: "100%",
      width: "40px",
      paddingRight: 20
    }
  });
};

var CatalogHeader = function CatalogHeader(_ref) {
  var classes = _ref.classes,
      showHamburger = _ref.showHamburger;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showSearchBar = _useState2[0],
      setShowSearchBar = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      userSearch = _useState4[0],
      setUserSearch = _useState4[1];

  var _useState5 = useState({
    menu: null // add search bar here?

  }),
      _useState6 = _slicedToArray(_useState5, 2),
      anchors = _useState6[0],
      setAnchors = _useState6[1];

  var handleSearchButtonClick = function handleSearchButtonClick() {
    setShowSearchBar(!showSearchBar);
  };

  var handleSearchChange = function handleSearchChange(event) {
    setUserSearch(event.target.value);
  };

  var handleHeaderSearch = function handleHeaderSearch() {
    var url = "".concat(window.appConfig.mainPageUrl, "?s=").concat(userSearch);
    location.href = url;
  };

  var toggleAnchorClick = function toggleAnchorClick(anchorKey, event) {
    setAnchors(Object.assign(Object.assign({}, anchors), _defineProperty({}, anchorKey, anchors[anchorKey] === null && event !== undefined ? event.currentTarget : null)));
  };

  var searchComponent = function searchComponent() {
    return React.createElement(React.Fragment, null, React.createElement(Hidden, {
      xsUp: showSearchBar
    }, React.createElement("div", {
      className: classes.searchDivButton
    }, React.createElement(Button, {
      onClick: handleSearchButtonClick,
      className: classes.searchButton
    }, React.createElement(SearchIcon, null)))), React.createElement(Slide, {
      direction: "left",
      in: showSearchBar,
      mountOnEnter: true,
      unmountOnExit: true
    }, React.createElement("div", null, React.createElement(OutlinedInput, {
      id: "search-field",
      placeholder: "Search",
      type: "text",
      value: userSearch,
      onChange: handleSearchChange,
      className: classes.searchBar,
      style: {
        height: window.innerWidth >= medSizeScreen ? 65 : 45,
        width: window.innerWidth <= smallSizeScreen ? "80hv" : 240
      },
      labelWidth: 0,
      onKeyDown: function onKeyDown(e) {
        if (e.key === "Enter") {
          e.preventDefault();
          handleHeaderSearch();
        }
      },
      endAdornment: React.createElement(InputAdornment, {
        position: "end"
      }, React.createElement(IconButton, {
        edge: "end",
        onClick: handleSearchButtonClick
      }, React.createElement(ClearIcon, {
        fontSize: "small"
      })))
    }))));
  };

  var _useState7 = useState(JSON.parse(localStorage.getItem("cart") || "[]").length),
      _useState8 = _slicedToArray(_useState7, 2),
      numOfCartItems = _useState8[0],
      setNumOfCartItems = _useState8[1];

  window.addEventListener("storage", function () {
    var cartItemsInStorage = JSON.parse(localStorage.getItem("cart") || "[]");

    if (cartItemsInStorage) {
      setNumOfCartItems(cartItemsInStorage.length);
    } else {
      setNumOfCartItems(0);
    }
  });

  var cartButton = function cartButton() {
    return React.createElement(Button, {
      href: "/checkout",
      disabled: window.location.href.includes("checkout"),
      classes: {
        disabled: classes.disabledCartIcon
      }
    }, React.createElement(Badge, {
      badgeContent: numOfCartItems,
      color: "primary",
      classes: {
        colorPrimary: classes.cartBadge
      },
      overlap: "rectangular"
    }, React.createElement(ShoppingCartOutlined, null)));
  };

  var getMobileHeader = function getMobileHeader() {
    return React.createElement("div", {
      className: classes.root
    }, React.createElement(AppBar, {
      className: classes.mobileBanner
    }, React.createElement(Toolbar, {
      variant: "dense",
      disableGutters: true
    }, React.createElement(Hidden, {
      xsDown: showSearchBar
    }, React.createElement("div", {
      className: classes.logoDiv
    }, React.createElement("a", {
      id: "mobile-header-home-logo",
      href: window.appConfig.mainPageUrl,
      key: "mobile-header-home-logo"
    }, React.createElement("img", {
      src: "/images/logo-mobile.svg",
      alt: "baylor-genetics-logo",
      className: classes.mobileLogo
    })))), showHamburger ? React.createElement(React.Fragment, null, React.createElement(Button, {
      style: {
        color: colors.deepSeaBlue
      },
      id: "hamburger",
      onClick: function onClick(event) {
        return toggleAnchorClick("menu", event);
      }
    }, React.createElement(MenuIcon, null)), Boolean(anchors.menu) && React.createElement(HeaderMenu, {
      anchorEl: anchors.menu,
      open: true,
      onClose: function onClose() {
        return toggleAnchorClick("menu", undefined);
      }
    })) : React.createElement(CompactMarketingMenu, null), searchComponent(), cartButton())));
  };

  var getDesktopHeader = function getDesktopHeader() {
    return React.createElement("div", {
      className: classes.root
    }, React.createElement(AppBar, {
      className: classes.banner
    }, React.createElement(Toolbar, {
      variant: "dense",
      disableGutters: true
    }, showHamburger ? React.createElement(React.Fragment, null, React.createElement(Button, {
      style: {
        color: colors.deepSeaBlue
      },
      id: "hamburger",
      onClick: function onClick(event) {
        return toggleAnchorClick("menu", event);
      }
    }, React.createElement(MenuIcon, null)), Boolean(anchors.menu) && React.createElement(HeaderMenu, {
      anchorEl: anchors.menu,
      open: true,
      onClose: function onClose() {
        return toggleAnchorClick("menu", undefined);
      }
    })) : React.createElement("div", null), React.createElement(Hidden, {
      lgDown: showSearchBar && window.innerWidth < 1150
    }, React.createElement("div", {
      className: classes.logoDiv
    }, React.createElement("a", {
      id: "Header-home-logo",
      href: window.appConfig.mainPageUrl,
      key: "Header-home-logo"
    }, React.createElement("img", {
      src: "/images/logo-horzrbg.svg",
      alt: "baylor-genetics-logo",
      className: classes.logo
    })))), React.createElement(MarketingMenu, null), searchComponent(), cartButton())));
  };

  return React.createElement(React.Fragment, null, window.innerWidth >= medSizeScreen ? getDesktopHeader() : getMobileHeader());
};

export default withStyles(styles)(CatalogHeader);