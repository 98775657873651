import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import { colors, fonts } from "../theme";
var useStyles = makeStyles(createStyles({
  breadcrumbs: Object.assign(Object.assign({}, fonts.fontStyles.breadcrumbs), {
    flexGrow: 1,
    marginTop: "1em",
    textTransform: "uppercase",
    verticalAlign: "top"
  }),
  breadcrumbLink: {
    textDecoration: "none",
    color: colors.deepSeaBlue
  },
  breadcrumbSlash: Object.assign(Object.assign({}, fonts.fontStyles.breadcrumbSlash), {
    margin: "0 0.25em"
  })
}));

var TestCatalogBreadcrumb = function TestCatalogBreadcrumb(_ref) {
  var test = _ref.test,
      ctcName = _ref.ctcName;
  var classes = useStyles();
  var testName = ctcName ? ctcName : test && test.name ? test.name : "";
  return React.createElement("div", {
    className: classes.breadcrumbs
  }, React.createElement("a", {
    href: "".concat(window.appConfig.catalogUrl),
    className: classes.breadcrumbLink
  }, "TEST CATALOG"), React.createElement("span", {
    className: classes.breadcrumbSlash
  }, "//"), React.createElement("span", null, React.createElement("a", {
    href: "".concat(window.appConfig.catalogUrl, "/details/") + (test ? test.code : ""),
    className: classes.breadcrumbLink
  }, testName)));
};

export default TestCatalogBreadcrumb;