import React, { useEffect } from "react";
import { SectionHeader, SectionInfo } from ".";
import { RadioGroup, FormControlLabel, Radio, makeStyles, FormHelperText } from "@material-ui/core";
var useStyles = makeStyles({
  descriptionInfoSection: {
    marginBottom: "0.5em"
  },
  formControl: {
    alignItems: "flex-start"
  },
  option: {
    margin: "0 9px 9px 9px",
    padding: 0
  },
  optionLabel: {
    display: "flex"
  },
  error: {
    color: "red"
  }
});

var ConsentOption = function ConsentOption(_ref) {
  var title = _ref.title,
      description = _ref.description,
      yesText = _ref.yesText,
      noText = _ref.noText,
      value = _ref.value,
      onChange = _ref.onChange,
      readonly = _ref.readonly,
      errorText = _ref.errorText;
  var classes = useStyles();
  var radioValue = value !== undefined ? value ? "yes" : "no" : undefined;

  var handleChange = function handleChange(event) {
    if (onChange) {
      onChange(event.target.value === "yes");
    }
  }; // to default to "No" and call the onChange so it saves in the order


  useEffect(function () {
    if (radioValue === undefined && !readonly && onChange) {
      onChange(title === "Consent for Testing");
    }
  }, [radioValue, readonly, onChange]);

  var ReadOnly = function ReadOnly() {
    return React.createElement(React.Fragment, null, value && React.createElement(SectionInfo, {
      style: {
        display: "flex"
      }
    }, React.createElement("span", {
      style: {
        display: "inline-block",
        textAlign: "left",
        width: "2.25em",
        marginRight: "0.25em",
        flex: "0 0 auto"
      }
    }, React.createElement("b", null, "YES")), " ", yesText), !value && React.createElement(SectionInfo, {
      style: {
        display: "flex"
      }
    }, React.createElement("span", {
      style: {
        display: "inline-block",
        textAlign: "left",
        width: "2.25em",
        marginRight: "0.25em",
        flex: "0 0 auto"
      }
    }, React.createElement("b", null, "NO")), " ", noText));
  };

  var Editable = function Editable() {
    return React.createElement(RadioGroup, {
      value: radioValue,
      onChange: handleChange
    }, React.createElement(FormControlLabel, {
      control: React.createElement(Radio, {
        className: classes.option
      }),
      classes: {
        root: classes.formControl
      },
      label: React.createElement(SectionInfo, {
        className: classes.optionLabel
      }, React.createElement("span", {
        style: {
          display: "inline-block",
          textAlign: "left",
          width: "2.25em",
          marginRight: "0.25em",
          flex: "0 0 auto"
        }
      }, React.createElement("b", null, "YES")), " ", yesText),
      value: "yes"
    }), React.createElement(FormControlLabel, {
      control: React.createElement(Radio, {
        className: classes.option
      }),
      classes: {
        root: classes.formControl
      },
      label: React.createElement(SectionInfo, {
        className: classes.optionLabel
      }, React.createElement("span", {
        style: {
          display: "inline-block",
          textAlign: "left",
          width: "2.25em",
          marginRight: "0.25em",
          flex: "0 0 auto"
        }
      }, React.createElement("b", null, "NO")), " ", noText),
      value: "no",
      style: {
        marginTop: "0.5em"
      }
    }));
  };

  return React.createElement("div", {
    style: {
      breakInside: "avoid"
    }
  }, title && React.createElement(SectionHeader, {
    style: {
      marginTop: "1.5em"
    }
  }, title), description && React.createElement("div", {
    style: {
      marginBottom: "0.5em"
    }
  }, React.createElement(SectionInfo, null, description)), readonly && React.createElement(ReadOnly, null), !readonly && React.createElement(Editable, null), errorText && React.createElement(FormHelperText, {
    className: classes.error
  }, errorText), React.createElement("br", null));
};

export default ConsentOption;