import React from "react";
import * as icons from "@material-ui/icons";
import { fonts, colors } from "../theme";
import { withStyles } from "@material-ui/core";
import { DefaultLayout } from "../components";

var NotFound = function NotFound(_ref) {
  var classes = _ref.classes;
  return React.createElement(DefaultLayout, null, React.createElement("div", {
    style: {
      textAlign: "center"
    },
    className: classes.font
  }, React.createElement(icons.ExploreTwoTone, {
    style: {
      fontSize: "10em"
    },
    htmlColor: colors.lightBrown
  }), React.createElement("br", null), React.createElement("div", {
    style: {
      color: colors.mediumBrown,
      fontWeight: 100,
      fontSize: fonts.sizes.xxl,
      marginBottom: "2em"
    }
  }, "Page not found"), React.createElement("div", {
    style: {
      color: colors.deepSeaBlue,
      fontWeight: 400,
      fontSize: fonts.sizes.sm
    }
  }, "The link you followed may be broken, or the page may have been removed.")));
};

export default withStyles(fonts.fontStyles)(NotFound);