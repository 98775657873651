import { useAuth0, UserState } from "@bg/shared/lib/auth/react-auth0-wrapper";
import { DefaultLayout, PrivateRoute } from "@bg/shared/lib/components";
import { fonts } from "@bg/shared/lib/theme";
import { deepSeaBlue, mediumGray } from "@bg/shared/lib/theme/colors";
import { EmailNotVerified, ErrorPage, NotFound } from "@bg/shared/lib/views";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// Views
// import {  Registration, SignUp } from "./views";
const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));
const KitOrder = lazy(() => import("./views/KitOrder"));
const OrderRedirect = lazy(() => import("./views/Order/OrderRedirect"));
const OrderHistory = lazy(() => import("./views/OrderHistory"));
const Registration = lazy(() => import("./views/Registration"));
const SignUp = lazy(() => import("./views/SignUp"));
const TreatmentGuidancePage = lazy(() => import("./views/TreatmentGuidance"));
const KitHistory = lazy(() => import("./views/KitHistory"));
const SampleDetail = lazy(() => import("./views/SampleDetail"));
const OrderDetails = lazy(() => import("./views/OrderDetails"));
const ProfilePage = lazy(() => import("./views/ProfilePage"));
const OrderManifestUpload = lazy(
  () => import("./views/Order/OrderManifestUpload"),
);

const useStyles = makeStyles({
  email: {
    color: mediumGray,
    fontWeight: "normal",
    textDecoration: "none",
  },
  icon: {
    fontSize: "10em",
  },
  isLoading: {
    paddingTop: "25%",
    height: "100vh",
    textAlign: "center",
  },
  waitingForApproval: {
    paddingTop: "15%",
    height: "50vh",
    lineHeight: "1em",
    textAlign: "center",
    color: deepSeaBlue,
    fontWeight: 400,
    fontSize: fonts.sizes.sm,
    fontFamily: "Roboto Condensed",
    "& span": {
      color: mediumGray,
    },
  },
  iFrame: {
    display: "none",
  },
});

const Routes: FunctionComponent = () => {
  const { userState, physician } = useAuth0();
  const classes = useStyles();

  useEffect(() => {
    const postCrossDomainMessage = () => {
      const doc = document.getElementById(
        "ifr-for-cross-domain",
      ) as HTMLIFrameElement;
      const token = sessionStorage.getItem("authToken");
      if (doc) {
        const contentWindow = doc.contentWindow;
        if (contentWindow && token) {
          const decodedToken = JSON.parse(atob(token.split(".")[1]));
          const tokenExpirationDate = decodedToken.exp * 1000;
          localStorage.setItem(
            "tokenExpirationDate",
            tokenExpirationDate.toString(),
          );
          if (physician) {
            contentWindow.postMessage(
              tokenExpirationDate,
              window.appConfig.catalogUrl,
            );
          }
        }
      }
    };
    postCrossDomainMessage();
  }, [physician]);

  const DefaultLayoutLoading = () => (
    <DefaultLayout>
      <div className={classes.isLoading}>
        Loading... <br />
        <br />
        <CircularProgress />
      </div>
    </DefaultLayout>
  );
  const renderByState = () => {
    switch (userState) {
      case UserState.Error:
        return (
          <DefaultLayout>
            <ErrorPage />
          </DefaultLayout>
        );
      case UserState.Loading:
        return DefaultLayoutLoading();
      case UserState.WaitingForApproval:
        return (
          <DefaultLayout>
            <div className={classes.waitingForApproval}>
              <h2>Thank you for signing up.</h2>
              <h2>Your account is waiting approval.</h2>
              <br />
              <span>
                Once your account is approved you will receive an email
                confirmation.
              </span>
              <br />
              <span>
                This can take 1-2 business days. Please contact{" "}
                <a
                  className={classes.email}
                  href="mailto: help@baylorgenetics.com"
                >
                  Baylor&nbsp;Genetics
                </a>{" "}
                for assistance.{" "}
              </span>
            </div>
          </DefaultLayout>
        );
      case UserState.NeedRegistration:
        return (
          <Switch>
            <PrivateRoute path="/register" component={Registration} />
            <Redirect to="/register" />
          </Switch>
        );
      default:
        return (
          <Switch>
            <PrivateRoute path="/kit-order" component={KitOrder} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/order/details/:id" component={OrderDetails} />
            <PrivateRoute path="/order" component={OrderRedirect} />
            <PrivateRoute
              path="/create/manifest"
              component={OrderManifestUpload}
            />
            <PrivateRoute path="/create" component={OrderRedirect} />
            <PrivateRoute path="/orders" component={OrderHistory} />
            <PrivateRoute path="/kits" component={KitHistory} />
            <PrivateRoute path="/sample/:sampleId" component={SampleDetail} />
            <PrivateRoute path="/profile" component={ProfilePage} />
            <Route path="/signup" component={SignUp} />
            <Route
              path="/treatment/:treatmentId"
              component={TreatmentGuidancePage}
            />
            <Route path="/email-not-verified" component={EmailNotVerified} />
            <Redirect exact from="/" to="/dashboard" />
            <Redirect exact from="/register" to="/dashboard" />
            <Route component={NotFound} />
          </Switch>
        );
    }
  };

  return (
    <BrowserRouter>
      <iframe
        src={`${window.appConfig.catalogUrl}`}
        className={classes.iFrame}
        id="ifr-for-cross-domain"
        title="ifr-for-cross-domain"
        // onLoad={() => postCrossDomainMessage()}
      />
      <Suspense fallback={DefaultLayoutLoading()}>{renderByState()}</Suspense>
    </BrowserRouter>
  );
};

export default Routes;
