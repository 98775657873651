import React from "react";
import Button from "@material-ui/core/Button";
import { darken, makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "../../theme";
var useStyles = makeStyles({
  button: {
    fontFamily: "Roboto Condensed",
    fontWeight: 200,
    background: colors.white,
    color: colors.powderBlue,
    borderColor: colors.powderBlue,
    padding: "0.125em 1.5em",
    textAlign: "center",
    margin: "0.125em",
    whiteSpace: "nowrap",
    "&:hover": {
      background: darken(colors.white, 0.1)
    },
    textTransform: "none"
  }
});

var SecondaryButton = function SecondaryButton(props) {
  var classes = useStyles();
  var fontSize = fonts.sizes.md;

  if (props.size === "small") {
    fontSize = fonts.sizes.sm;
  } else if (props.size === "large") {
    fontSize = fonts.sizes.xl;
  }

  return React.createElement(Button, Object.assign({
    className: classes.button,
    style: {
      fontSize: fontSize
    },
    variant: "outlined"
  }, props));
};

export default SecondaryButton;