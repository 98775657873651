import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _root, _root2;

import { createTheme, makeStyles } from "@material-ui/core/styles";
import { deepSeaBlue, mediumBrown, seaShell } from "./colors";
import { fonts } from "./fonts";
import { colors } from ".";
var defaultTheme = createTheme();
export var appTheme = createTheme({
  typography: {
    fontFamily: fonts
  },
  palette: {
    primary: {
      main: deepSeaBlue,
      dark: deepSeaBlue // focusedField

    },
    secondary: {
      main: mediumBrown // checkBoxes

    }
  },
  overrides: {
    MuiInputLabel: {
      root: (_root = {
        padding: "0 8px"
      }, _defineProperty(_root, defaultTheme.breakpoints.only("sm"), {
        fontSize: "1rem"
      }), _defineProperty(_root, defaultTheme.breakpoints.only("xs"), {
        fontSize: "0.8rem"
      }), _defineProperty(_root, "pointerEvents", "none"), _root),
      formControl: {
        top: 4
      },
      shrink: {
        top: 0,
        left: -8
      }
    },
    MuiInputBase: {
      input: {
        background: seaShell,
        padding: "12px 4px 0 4px"
      }
    },
    MuiSelect: {
      root: (_root2 = {}, _defineProperty(_root2, defaultTheme.breakpoints.only("sm"), {
        fontSize: "1rem"
      }), _defineProperty(_root2, defaultTheme.breakpoints.only("xs"), {
        fontSize: "0.8rem"
      }), _root2)
    },
    MuiOutlinedInput: {
      multiline: {
        padding: 3
      }
    }
  }
});
export var useFormStyles = makeStyles(function (theme) {
  return {
    smallLabel: {
      fontSize: ".75em",
      marginLeft: -3
    },
    mediumLabel: {
      fontSize: "1em",
      marginLeft: -3,
      lineHeight: "1.3em"
    },
    marginSpacing: {
      marginTop: "1em",
      marginLeft: "1em"
    },
    bottomMargin: {
      marginBottom: "0.5em"
    }
  };
});
export var link = {
  textDecoration: "none",
  fontWeight: "bold",
  color: colors.powderBlue
};
export var gridContainerSpacing = 2;
export var useOutlinedTextFieldStyles = makeStyles(function (theme) {
  return {
    outlinedTextField: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  };
});