import React from "react";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";
var useStyles = makeStyles({
  loadingContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  }
});

var Loading = function Loading() {
  var classes = useStyles();
  return React.createElement(Grid, {
    item: true,
    className: classes.loadingContainer,
    xs: 12
  }, "Loading...", React.createElement("br", null), React.createElement(CircularProgress, null));
};

export default Loading;