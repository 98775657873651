import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from "react";
import { InputLabel, FormControl, FormHelperText, Select, Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { gridContainerSpacing } from "../theme/themes";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import * as dateFns from "date-fns";
import CustomSelect from "./CustomSelect";
import { colors } from "../theme";
var monthOptions = Array.from({
  length: 12
}, function (_, i) {
  return i + 1;
});
var year = new Date().getFullYear();
var yearOptions = Array.from({
  length: 126
}, function (_, i) {
  return year - i;
});
var useStyles = makeStyles(function (theme) {
  return {
    label: {
      color: colors.sleepyGrey,
      fontWeight: "bold",
      paddingLeft: 15,
      width: "400%"
    },
    internalLabel: {
      "&.MuiInputLabel-formControl": {
        transform: "translate(0, 32px) scale(1)" // adjust the translation as needed

      }
    }
  };
});

var DatePicker = function DatePicker(_ref) {
  var label = _ref.label,
      onChange = _ref.onChange,
      value = _ref.value,
      id = _ref.id,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      validationError = _ref.validationError,
      required = _ref.required,
      _ref$preventFutureDat = _ref.preventFutureDate,
      preventFutureDate = _ref$preventFutureDat === void 0 ? false : _ref$preventFutureDat,
      _ref$registrationVari = _ref.registrationVariant,
      registrationVariant = _ref$registrationVari === void 0 ? false : _ref$registrationVari;

  var _useState = useState(""),
      _useState2 = _slicedToArray(_useState, 2),
      month = _useState2[0],
      setMonth = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      day = _useState4[0],
      setDay = _useState4[1];

  var _useState5 = useState(""),
      _useState6 = _slicedToArray(_useState5, 2),
      year = _useState6[0],
      setYear = _useState6[1];

  var _useState7 = useState(validationError),
      _useState8 = _slicedToArray(_useState7, 2),
      error = _useState8[0],
      setError = _useState8[1];

  var _useState9 = useState(value),
      _useState10 = _slicedToArray(_useState9, 2),
      curValue = _useState10[0],
      setCurValue = _useState10[1];

  var todayDate = zonedTimeToUtc(new Date(), "America/Chicago");
  var leapYearTestDate = year ? new Date(parseInt(year), 0, 1) : new Date(2024, 1, 29); // use leap year as default so they can select day 29. Once they set the year, it will reset the day of year is not leap

  var daysInMonth = {
    1: 31,
    2: dateFns.isLeapYear(leapYearTestDate) ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  };
  var classes = useStyles();
  useEffect(function () {
    if (day > daysInMonth[month]) {
      setDay("");
    }

    if (month && day && year && onChange !== undefined) {
      var zonedDate = zonedTimeToUtc(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)), "America/Chicago");

      if (dateFns.isValid(zonedDate)) {
        setCurValue(zonedDate);
        onChange(zonedDate);
        setError("");
      } else {
        setError("Invalid");
      }
    }
  }, [month, day, year]);
  useEffect(function () {
    if (!moment(value).isSame(curValue)) {
      var date = value ? utcToZonedTime(value, "America/Chicago") : undefined;
      setMonth(date && dateFns.format(date, "M") || "");
      setDay(date && dateFns.format(date, "d") || "");
      setYear(date && dateFns.format(date, "yyyy") || "");
    }

    if (value && dateFns.isValid(new Date(value)) && !month && !day && !year) {
      var _date = utcToZonedTime(value, "America/Chicago");

      setMonth(dateFns.format(_date, "M"));
      setDay(dateFns.format(_date, "d"));
      setYear(dateFns.format(_date, "yyyy"));
    }
  }, [value]);
  useEffect(function () {
    setError(validationError);
  }, [validationError]);

  var handleChange = function handleChange(event) {
    switch (event.target.name) {
      case "year":
        setYear(event.target.value);
        break;

      case "day":
        setDay(event.target.value);
        break;

      case "month":
        setMonth(event.target.value);
        break;
    }
  };

  var handleCustomChange = function handleCustomChange(event) {
    switch (event.target.name) {
      case "year":
        setYear(event.target.value);
        break;

      case "day":
        setDay(event.target.value);
        break;

      case "month":
        setMonth(event.target.value);
        break;
    }
  };

  function getOptions(optionsArray, type) {
    return optionsArray.map(function (value) {
      var date;

      switch (type) {
        case "month":
          date = zonedTimeToUtc(new Date(parseInt(year || "1900"), value - 1, parseInt(day || "1")), "America/Chicago");
          break;

        case "day":
          date = zonedTimeToUtc(new Date(parseInt(year || "1900"), parseInt(month || "1") - 1, value), "America/Chicago");
          break;

        case "year":
          date = zonedTimeToUtc(new Date(value, parseInt(month || "1") - 1, parseInt(day || "1")), "America/Chicago");
          break;

        default:
          break;
      }

      if (preventFutureDate && date && date > todayDate) {
        return null;
      }

      return {
        value: value,
        label: value.toString()
      };
    }).filter(Boolean).map(function (choice, index) {
      return React.createElement("option", {
        key: index,
        value: choice === null || choice === void 0 ? void 0 : choice.value
      }, choice === null || choice === void 0 ? void 0 : choice.label);
    });
  }

  var validateRequired = function validateRequired() {
    if (required && (!month || !day || !year)) setError("Required");
  };

  return React.createElement(Grid, {
    container: true,
    spacing: gridContainerSpacing,
    id: id
  }, React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 5,
    md: 5
  }, React.createElement(FormControl, {
    fullWidth: true,
    error: !!error
  }, registrationVariant ? React.createElement(React.Fragment, null, " ", React.createElement(InputLabel, {
    shrink: true,
    className: classes.label
  }, required ? "".concat(label, " *") : label), React.createElement(InputLabel, {
    className: classes.internalLabel,
    shrink: false
  }, !month && "Month"), React.createElement(CustomSelect, {
    name: "month",
    onChange: handleCustomChange,
    onBlur: validateRequired,
    value: month,
    placeholder: "Month",
    id: "".concat(id, "-month"),
    disabled: disabled,
    style: {
      fontSize: 15
    }
  }, React.createElement("option", {
    value: ""
  }), getOptions(monthOptions, "month")), " ") : React.createElement(React.Fragment, null, React.createElement(InputLabel, {
    shrink: true
  }, required ? "".concat(label, " *") : label), React.createElement(InputLabel, {
    shrink: false
  }, !month && "Month"), React.createElement(Select, {
    native: true,
    name: "month",
    onChange: handleChange,
    onBlur: validateRequired,
    value: month,
    placeholder: "Month",
    id: "".concat(id, "-month"),
    disabled: disabled,
    style: {
      fontSize: 15
    }
  }, React.createElement("option", {
    value: ""
  }), getOptions(monthOptions, "month"))), error && React.createElement(FormHelperText, {
    style: {
      width: "400%"
    }
  }, error))), React.createElement(Grid, {
    item: true,
    xs: 6,
    sm: 3,
    md: 3
  }, React.createElement(FormControl, {
    fullWidth: true,
    error: !!error
  }, registrationVariant ? React.createElement(React.Fragment, null, React.createElement(InputLabel, {
    className: classes.internalLabel,
    shrink: false
  }, !day && "Day"), React.createElement(CustomSelect, {
    name: "day",
    onChange: handleCustomChange,
    onBlur: validateRequired,
    value: day,
    id: "".concat(id, "-day"),
    disabled: disabled
  }, React.createElement("option", {
    value: ""
  }), getOptions(Array.from({
    length: daysInMonth[month] || 28
  }, function (_, i) {
    return i + 1;
  }), "day"))) : React.createElement(React.Fragment, null, React.createElement(InputLabel, {
    shrink: false
  }, !day && "Day"), React.createElement(Select, {
    native: !registrationVariant,
    name: "day",
    onChange: handleChange,
    onBlur: validateRequired,
    value: day,
    id: "".concat(id, "-day"),
    disabled: disabled
  }, React.createElement("option", {
    value: ""
  }), getOptions(Array.from({
    length: daysInMonth[month] || 28
  }, function (_, i) {
    return i + 1;
  }), "day"))))), React.createElement(Grid, {
    item: true,
    xs: 6,
    sm: 4,
    md: 4
  }, React.createElement(FormControl, {
    fullWidth: true,
    error: !!error
  }, registrationVariant ? React.createElement(React.Fragment, null, React.createElement(InputLabel, {
    className: classes.internalLabel,
    shrink: false
  }, !year && "Year"), React.createElement(CustomSelect, {
    name: "year",
    onChange: handleCustomChange,
    onBlur: validateRequired,
    value: year,
    id: "".concat(id, "-year"),
    disabled: disabled
  }, React.createElement("option", {
    value: ""
  }), getOptions(yearOptions, "year"))) : React.createElement(React.Fragment, null, React.createElement(InputLabel, {
    shrink: false
  }, !year && "Year"), React.createElement(Select, {
    native: !registrationVariant,
    name: "year",
    onChange: handleChange,
    onBlur: validateRequired,
    value: year,
    id: "".concat(id, "-year"),
    disabled: disabled
  }, React.createElement("option", {
    value: ""
  }), getOptions(yearOptions, "year"))))));
};

export default DatePicker;