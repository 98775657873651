import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { Collapse, IconButton, List, ListItem, ListItemText, Menu } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore, Close } from "@material-ui/icons";
import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { colors } from "../../../theme";
import { aboutUsItems, newsAndUpdatesItems, providersOrderingItems, providersResourcesItems, providersSupportItems, testComprehensiveItems, testReproductuveItems, testSpecializedModalityItems } from "./CatalogHeaderHelper";
import SubMenuItems from "./CatalogHeaderHelper";

var styles = function styles() {
  return createStyles({
    compactMainMenuItem: {
      textTransform: "uppercase",
      height: 40,
      "&.Mui-selected, &.Mui-selected:hover": {
        background: colors.white,
        color: colors.powderBlue
      }
    },
    compactSubMenuItem: {
      textTransform: "uppercase",
      height: 40,
      paddingLeft: 30,
      background: colors.deepSeaBlue,
      color: colors.powderBlue,
      "&:hover, &$focusVisible": {
        background: colors.powderBlue,
        color: colors.white
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        background: colors.powderBlue,
        color: colors.white
      }
    },
    noPadding: {
      padding: 0,
      "&.MuiMenu-list, &.MuiList-padding, .MuiList-padding": {
        padding: 0
      }
    }
  });
};

var CompactMarketingMenu = function CompactMarketingMenu(_ref) {
  var classes = _ref.classes;

  var _useState = useState({
    providers: false,
    providersOrdering: false,
    providersSupport: false,
    providersResources: false,
    tests: false,
    testsComprehensive: false,
    testsReproductive: false,
    testSpecializedModality: false,
    aboutUs: false,
    newsAndUpdates: false
  }),
      _useState2 = _slicedToArray(_useState, 2),
      collapse = _useState2[0],
      setCollapse = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      compactMenuAnchor = _useState4[0],
      setCompactMenuAnchor = _useState4[1];

  var handleCompactMenuAnchor = function handleCompactMenuAnchor(event) {
    setCompactMenuAnchor(event.currentTarget);
  };

  var handleCompactMenuAnchorClose = function handleCompactMenuAnchorClose() {
    setCompactMenuAnchor(null);
  };

  var subMenuLeftMargin = 40;
  return React.createElement(React.Fragment, null, React.createElement(IconButton, {
    onClick: handleCompactMenuAnchor,
    style: {
      height: "100%"
    }
  }, Boolean(compactMenuAnchor) ? React.createElement(Close, null) : React.createElement(MenuIcon, null)), React.createElement(Menu, {
    id: "compact-main-menu",
    anchorEl: compactMenuAnchor,
    open: Boolean(compactMenuAnchor),
    onClose: handleCompactMenuAnchorClose,
    keepMounted: true,
    getContentAnchorEl: null,
    className: classes.noPadding,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    PaperProps: {
      style: {
        width: "100%",
        padding: 0
      }
    }
  }, React.createElement(List, {
    className: classes.noPadding
  }, React.createElement(ListItem, {
    button: true,
    className: classes.compactMainMenuItem,
    onClick: function onClick() {
      return location.assign("https://www.baylorgenetics.com/partnerships/");
    }
  }, React.createElement(ListItemText, {
    primary: "Partnerships"
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        providers: !collapse.providers
      }));
    },
    className: classes.compactMainMenuItem,
    selected: collapse.providers
  }, React.createElement(ListItemText, {
    primary: "Providers"
  }), collapse.providers ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.providers,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(List, {
    className: classes.noPadding
  }, React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        providersOrdering: !collapse.providersOrdering
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.providersOrdering
  }, React.createElement(ListItemText, {
    primary: "Ordering"
  }), collapse.providersOrdering ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.providersOrdering,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: providersOrderingItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        providersSupport: !collapse.providersSupport
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.providersSupport
  }, React.createElement(ListItemText, {
    primary: "Support"
  }), collapse.providersSupport ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.providersSupport,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: providersSupportItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        providersResources: !collapse.providersResources
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.providersResources
  }, React.createElement(ListItemText, {
    primary: "Resources"
  }), collapse.providersResources ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.providersResources,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: providersResourcesItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })))), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        tests: !collapse.tests
      }));
    },
    className: classes.compactMainMenuItem,
    selected: collapse.tests
  }, React.createElement(ListItemText, {
    primary: "Tests"
  }), collapse.tests ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.tests,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(List, {
    className: classes.noPadding
  }, React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        testsComprehensive: !collapse.testsComprehensive
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.testsComprehensive
  }, React.createElement(ListItemText, {
    primary: "Comprehensive"
  }), collapse.testsComprehensive ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.testsComprehensive,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: testComprehensiveItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        testsReproductive: !collapse.testsReproductive
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.testsReproductive
  }, React.createElement(ListItemText, {
    primary: "Reproductive"
  }), collapse.testsReproductive ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.testsReproductive,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: testReproductuveItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        testSpecializedModality: !collapse.testSpecializedModality
      }));
    },
    className: classes.compactSubMenuItem,
    selected: collapse.testSpecializedModality
  }, React.createElement(ListItemText, {
    primary: "Specialized Modality"
  }), collapse.testSpecializedModality ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.testSpecializedModality,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: testSpecializedModalityItems,
    width: "100%",
    leftMargin: subMenuLeftMargin
  })))), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        aboutUs: !collapse.aboutUs
      }));
    },
    className: classes.compactMainMenuItem,
    selected: collapse.aboutUs
  }, React.createElement(ListItemText, {
    primary: "About Us"
  }), collapse.aboutUs ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.aboutUs,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: aboutUsItems,
    width: "100%",
    leftMargin: subMenuLeftMargin / 2
  })), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        newsAndUpdates: !collapse.newsAndUpdates
      }));
    },
    className: classes.compactMainMenuItem,
    selected: collapse.newsAndUpdates
  }, React.createElement(ListItemText, {
    primary: "News & Updates"
  }), collapse.newsAndUpdates ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.newsAndUpdates,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(SubMenuItems, {
    items: newsAndUpdatesItems,
    width: "100%",
    leftMargin: subMenuLeftMargin / 2
  })))));
};

export default withStyles(styles)(CompactMarketingMenu);