import React from "react";
import Select from "@material-ui/core/Select";
import { FormControl, InputLabel } from "@material-ui/core";
import CustomSelect from "../CustomSelect";
var values = {
  Address: ["Primary", "Billing", "Mailing", "All"],
  InstitutionPhone: ["Home", "Work", "Cell", "Fax"],
  ContactPhone: ["Home", "Work", "Temp", "Old", "Mobile"]
};

var TypePicker = function TypePicker(props) {
  if (props.version === 2) {
    var label = "".concat(props.type === "Address" ? props.type : "Phone", " Type");
    return React.createElement(FormControl, {
      fullWidth: props.fullWidth
    }, React.createElement(CustomSelect, Object.assign({
      label: label
    }, props), !["ContactPhone"].includes(props.type) && React.createElement("option", {
      value: ""
    }), values[props.type].map(function (value) {
      return React.createElement("option", {
        key: value
      }, value);
    })));
  }

  return React.createElement(FormControl, {
    fullWidth: props.fullWidth
  }, React.createElement(InputLabel, {
    htmlFor: "type",
    style: props.error ? {
      color: "red"
    } : {}
  }, props.type === "Address" ? props.type : "Phone", " ", props.required ? "Type *" : "Type"), React.createElement(Select, Object.assign({
    native: true
  }, props), !["ContactPhone"].includes(props.type) && React.createElement("option", {
    value: ""
  }), values[props.type].map(function (value) {
    return React.createElement("option", {
      key: value
    }, value);
  })));
};

export default TypePicker;