import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { colors, fonts } from "../theme";
var defaultTheme = createTheme();
var useStyles = makeStyles(createStyles({
  label: _defineProperty({
    fontFamily: fonts.fonts,
    color: colors.mediumBrown,
    fontSize: fonts.sizes.xxs
  }, defaultTheme.breakpoints.down("md"), {
    fontSize: ".75em"
  })
}));

var Label = function Label(_ref) {
  var children = _ref.children;
  var classes = useStyles();
  return React.createElement("div", {
    className: classes.label
  }, children);
};

export default Label;