import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import { colors, fonts } from "../theme";
var useStyles = makeStyles(createStyles({
  testName: {
    marginTop: "1em",
    marginBottom: "0.5em",
    fontSize: fonts.sizes.xxl,
    color: colors.deepSeaBlue
  },
  testCode: {
    color: colors.powderBlue,
    borderRight: "2px solid ".concat(colors.deepSeaBlue),
    paddingRight: "0.25em",
    marginRight: "0.25em"
  }
}));

var TestTitle = function TestTitle(_ref) {
  var test = _ref.test,
      ctcName = _ref.ctcName;
  var classes = useStyles();
  var testName = ctcName ? ctcName : test && test.name ? test.name : "";

  var testCodeAndDivider = function testCodeAndDivider() {
    if (test) {
      if (test.displayTestCode) {
        if (test.displayTestCode.trim() === "" || test.displayTestCode.toLowerCase() === "none") {
          return React.createElement(React.Fragment, null);
        } else {
          return React.createElement(React.Fragment, null, React.createElement("span", {
            className: classes.testCode
          }, test.displayTestCode), "\u200B");
        }
      } else {
        return React.createElement(React.Fragment, null, React.createElement("span", {
          className: classes.testCode
        }, test.code), "\u200B");
      }
    }

    return React.createElement(React.Fragment, null);
  };

  return React.createElement("div", {
    className: classes.testName
  }, testCodeAndDivider(), test && React.createElement("span", {
    dangerouslySetInnerHTML: {
      __html: testName
    }
  }));
};

export default TestTitle;