import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { fonts } from "../../theme";
var useStyles = makeStyles({
  selectedButton: {
    fontFamily: "Roboto Condensed",
    fontSize: fonts.sizes.xxs,
    background: "lightgrey",
    color: "black",
    padding: "1em 0.5em",
    height: "1.5em",
    lineHeight: "1.3em",
    textAlign: "left",
    justifyContent: "start"
  },
  button: {
    fontFamily: "Roboto Condensed",
    fontSize: fonts.sizes.xxs,
    justifyContent: "start",
    background: "transparent",
    color: "black",
    padding: "1em 0.5em",
    height: "1.5em",
    lineHeight: "1.3em",
    textAlign: "left"
  }
});

var PanelButton = function PanelButton(props) {
  var classes = useStyles();
  return React.createElement(Button, Object.assign({
    className: props.selected ? classes.selectedButton : classes.button
  }, props));
};

export default PanelButton;