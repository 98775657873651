import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useState } from "react";
import { Button, createStyles, withStyles, Popover } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import Overlay from "./Overlay";

var styles = function styles(theme) {
  return createStyles({
    headerIconRoot: {
      textTransform: "none",
      fontWeight: "normal"
    },
    headerIconLabel: _defineProperty({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "0.75rem"
    }, theme.breakpoints.down("md"), {
      fontSize: "0.6rem"
    })
  });
};

var VobDropdown = function VobDropdown(_ref) {
  var tests = _ref.tests,
      classes = _ref.classes;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      anchor = _useState2[0],
      setAnchor = _useState2[1];

  var toggleAnchorClick = function toggleAnchorClick(event) {
    if (anchor) {
      setAnchor(null);
    } else if (event) {
      setAnchor(event.currentTarget);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(Button, {
    id: "Header-vob-button",
    onClick: toggleAnchorClick,
    classes: {
      root: classes.headerIconRoot,
      label: classes.headerIconLabel
    }
  }, React.createElement(AddBox, null), React.createElement("span", null, "Create VOB")), React.createElement(Popover, {
    id: anchor ? "simple-popover" : undefined,
    open: Boolean(anchor),
    anchorEl: anchor,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    onClick: function onClick() {
      return toggleAnchorClick();
    },
    onClose: function onClose() {
      return toggleAnchorClick();
    },
    disableRestoreFocus: true
  }, React.createElement(Overlay, {
    tests: tests,
    header: "Create New VOB",
    urlPrefix: "/create?is_vob=true&test="
  })));
};

export default withStyles(styles)(VobDropdown);