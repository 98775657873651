import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _classCallCheck from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/classCallCheck";
import React, { createContext, useContext, useState, useEffect } from "react";
import { getCountries, getStates, getEthnicities, getDocumentTypes } from "../../dal/lookups";

var Lookups = function Lookups() {
  _classCallCheck(this, Lookups);

  this.states = [];
  this.countries = [];
  this.ethnicities = [];
  this.documentTypes = [];
};

var LookupContext = createContext(new Lookups());
export var useLookups = function useLookups() {
  return useContext(LookupContext);
};
export var LookupProvider = function LookupProvider(_ref) {
  var children = _ref.children;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      states = _useState2[0],
      setStates = _useState2[1];

  var _useState3 = useState([]),
      _useState4 = _slicedToArray(_useState3, 2),
      countries = _useState4[0],
      setCountries = _useState4[1];

  var _useState5 = useState([]),
      _useState6 = _slicedToArray(_useState5, 2),
      ethnicities = _useState6[0],
      setEthnicities = _useState6[1];

  var _useState7 = useState([]),
      _useState8 = _slicedToArray(_useState7, 2),
      documentTypes = _useState8[0],
      setDocumentTypes = _useState8[1];

  useEffect(function () {
    var doLookups = function doLookups() {
      getStates().then(function (result) {
        return setStates(result.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        }));
      });
      getCountries().then(function (result) {
        return setCountries(result);
      });
      getEthnicities().then(function (result) {
        return setEthnicities(result);
      });
      getDocumentTypes().then(function (result) {
        return setDocumentTypes(result);
      });
    };

    doLookups();
    setInterval(function () {
      doLookups();
    }, 15 * 60 * 1000);
  }, []);
  return React.createElement(LookupContext.Provider, {
    value: {
      states: states,
      countries: countries,
      ethnicities: ethnicities,
      documentTypes: documentTypes
    }
  }, children);
};