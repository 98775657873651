import React from "react";
import * as icons from "@material-ui/icons";
import { fonts, colors } from "../theme";
import { withStyles } from "@material-ui/core";
import { DefaultLayout } from "../components";

var EmailNotVerified = function EmailNotVerified(_ref) {
  var classes = _ref.classes;
  return React.createElement(DefaultLayout, null, React.createElement("div", {
    style: {
      textAlign: "center"
    },
    className: classes.font
  }, React.createElement(icons.MailOutlineTwoTone, {
    style: {
      fontSize: "10em"
    },
    htmlColor: colors.lightBrown
  }), React.createElement("br", null), React.createElement("div", {
    style: {
      color: colors.mediumBrown,
      fontWeight: 100,
      fontSize: fonts.sizes.xxl,
      marginBottom: "2em"
    }
  }, "Please verify your email."), React.createElement("div", {
    style: {
      color: colors.deepSeaBlue,
      fontWeight: 400,
      fontSize: fonts.sizes.sm
    }
  }, React.createElement("p", null, "A verification email has been sent to the sign-up email address. Please verify your account via the link provided in the email."), React.createElement("p", null, "If problem persists, please contact", " ", React.createElement("a", {
    href: "mailto: help@baylorgenetics.com",
    style: {
      color: colors.deepSeaBlue
    }
  }, "Baylor\xA0Genetics"), " ", "for support."))));
};

export default withStyles(fonts.fontStyles)(EmailNotVerified);