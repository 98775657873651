import { makeStyles, createStyles } from "@material-ui/core/styles";
var useStyles = makeStyles(function (theme) {
  return createStyles({
    nestedItem: {
      paddingLeft: theme.spacing(4)
    },
    accordion: {
      width: "100%",
      boxShadow: "none"
    },
    accordionDetail: {
      padding: "0 0 0 2em",
      "& ul": {
        padding: 0
      }
    },
    listIcon: {
      minWidth: "36px"
    }
  });
});
export default useStyles;