import React from "react";
import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme";
export var providersOrderingItems = [{
  url: "https://www.bcm.edu/research/medical-genetics-labs/tests.cfm",
  title: "Test Menu"
}, {
  url: "https://www.baylorgenetics.com/prenatal-sample-requirements/",
  title: "Prenatal Sample Requirements"
}, {
  url: "https://www.baylorgenetics.com/reqs/",
  title: "Requisitions"
}, {
  url: "https://www.baylorgenetics.com/plans/",
  title: "Insurance Plans"
}, {
  url: "https://www.baylorgenetics.com/consent/",
  title: "Consent Forms"
}, {
  url: "https://www.baylorgenetics.com/supplies/",
  title: "Test Kit Supplies"
}, {
  url: "https://www.baylorgenetics.com/vob/",
  title: "Benefits Verification"
}, {
  url: "https://www.baylorgenetics.com/forms/",
  title: "Policies & Forms"
}];
export var providersSupportItems = [{
  url: "https://www.baylorgenetics.com/support/genetic-counselors/",
  title: "Genetic Counselors"
}, {
  url: "https://generesults.baylorgenetics.com/Login.aspx",
  title: "Gene Results"
}, {
  url: "https://www.baylorgenetics.com/assistance/",
  title: "Patient Assistance"
}, {
  url: "https://www.baylorgenetics.com/support/product-updates/",
  title: "Product Updates"
}, {
  url: "https://www.baylorgenetics.com/support/add-on-test/",
  title: "Test Addition"
}, {
  url: "https://www.baylorgenetics.com/support/cancel-test/",
  title: "Test Cancellation"
}, {
  url: "https://www.baylorgenetics.com/faqs-for-providers-and-patients/",
  title: "FAQs for Providers and Patients"
}];
export var providersResourcesItems = [{
  url: "https://www.bcm.edu/research/medical-genetics-labs/disorder.cfm",
  title: "Array Coverage Search"
}, {
  url: "https://www.baylorgenetics.com/array-publications/",
  title: "Array Publications"
}, {
  url: "https://www.bcm.edu/research/medical-genetics-labs/mitome.cfm",
  title: "Mitome Search"
}, {
  url: "https://www.bcm.edu/research/medical-genetics-labs/exome.cfm",
  title: "Whole Exome Coverage Search"
}, {
  url: "https://www.baylorgenetics.com/variant-classification/",
  title: "Variant Classification"
}];
export var testComprehensiveItems = [{
  url: "https://www.baylorgenetics.com/whole-exome-sequencing/",
  title: "Whole Exome Sequencing"
}, {
  url: "https://www.baylorgenetics.com/whole-genome-sequencing/",
  title: "Whole Genome Sequencing"
}];
export var testSpecializedModalityItems = [{
  url: "https://www.baylorgenetics.com/cma/",
  title: "Chromosomal Microarray Analysis"
}, {
  url: "https://www.baylorgenetics.com/global-maps/",
  title: "Global MAPS™"
}, {
  url: "https://www.baylorgenetics.com/mitochondrial/",
  title: "Mitochondrial"
}];
export var testReproductuveItems = [{
  url: "https://www.baylorgenetics.com/preseek/",
  title: "Preseek™"
}, {
  url: "https://www.baylorgenetics.com/geneaware/",
  title: "Geneaware™"
}];
export var aboutUsItems = [{
  url: "https://www.baylorgenetics.com/about/",
  title: "About Us"
}, {
  url: "https://www.baylorgenetics.com/our-people/",
  title: "Our People"
}, {
  url: "https://www.baylorgenetics.com/history/",
  title: "Our History"
}, {
  url: "https://www.baylorgenetics.com/licenses/",
  title: "Our Licenses"
}, {
  url: "https://www.baylorgenetics.com/careers/",
  title: "Careers"
}];
export var newsAndUpdatesItems = [{
  url: "https://www.baylorgenetics.com/news/",
  title: "Blog"
}, {
  url: "https://www.baylorgenetics.com/publications/",
  title: "Publications"
}, {
  url: "https://www.baylorgenetics.com/press-releases/",
  title: "Press Releases"
}, {
  url: "https://www.baylorgenetics.com/support/product-updates/",
  title: "Product Updates"
}, {
  url: "https://www.baylorgenetics.com/conferences/",
  title: "Conferences"
}];

var styles = function styles() {
  return createStyles({
    focusVisible: {},
    subMenuButton: {
      background: colors.deepSeaBlue,
      color: colors.white,
      height: 40,
      textAlign: "left",
      alignItems: "center",
      justifyContent: "left",
      "&:hover, &$focusVisible": {
        background: colors.powderBlue
      }
    },
    subMenuTitle: {
      flexDirection: "column",
      textTransform: "uppercase",
      padding: "0px 0px 0px 10px",
      background: "inherit",
      color: colors.white,
      textAlign: "left",
      justifyContent: "left",
      "&:hover, &$focusVisible": {
        background: colors.powderBlue
      }
    },
    subMenuLinkButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 10,
      bottom: 15,
      display: "flex",
      alignItems: "left",
      justifyContent: "left"
    }
  });
};

var SubMenuItems = function SubMenuItems(_ref) {
  var classes = _ref.classes,
      items = _ref.items,
      width = _ref.width,
      leftMargin = _ref.leftMargin;
  return React.createElement(React.Fragment, null, items.map(function (item) {
    return React.createElement(ButtonBase, {
      focusRipple: true,
      key: "".concat(item.title.replace(" ", "-")),
      className: classes.subMenuButton,
      focusVisibleClassName: classes.focusVisible,
      style: {
        width: width
      }
    }, React.createElement("a", {
      className: classes.subMenuLinkButton,
      href: item.url
    }, React.createElement(Typography, {
      component: "span",
      variant: "subtitle1",
      color: "inherit",
      className: classes.subMenuTitle,
      key: "".concat(item.title.replace(" ", "-"), "-title"),
      align: "left",
      style: {
        width: width,
        marginLeft: leftMargin ? leftMargin : 0
      }
    }, item.title)));
  }));
};

export default withStyles(styles)(SubMenuItems);