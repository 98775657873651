import {
	ApplicationInsights,
	ITelemetryPlugin,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
	config: {
		connectionString: window.appConfig.appInsightConnection,
		extensions: [reactPlugin as unknown as ITelemetryPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});
appInsights.loadAppInsights();
