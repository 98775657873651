import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Drawer, IconButton } from "@material-ui/core";
import { colors } from "../theme";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import CardTravelOutlinedIcon from "@material-ui/icons/CardTravelOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssignmentReturnOutlinedIcon from "@material-ui/icons/AssignmentReturnOutlined";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
var drawerWidth = 245;
var useStyles = makeStyles(function (theme) {
  return {
    root: {
      display: "flex"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 10
    },
    drawerPaper: {
      width: drawerWidth
    },
    nestedItem: {
      paddingLeft: theme.spacing(4)
    },
    listIcon: {
      minWidth: "36px"
    },
    expandButton: {
      // TODO: Attach to sidemenu
      // currently hidden
      position: "fixed",
      top: "2em",
      backgroundColor: colors.white,
      outline: colors.darkGrey,
      // zIndex: 50,
      display: "none"
    },
    iconImage: {
      filter: "invert(56%) sepia(0%) saturate(1421%) hue-rotate(193deg) brightness(78%) contrast(100%)"
    },
    drawerHeader: {
      height: "63px",
      backgroundColor: colors.deepSeaBlue // necessary for content to be below app bar

    },
    catalogDrawerHeader: {
      height: "75px",
      backgroundColor: colors.white // necessary for content to be below app bar

    }
  };
});

var Sidebar = function Sidebar(_ref) {
  var hide = _ref.hide,
      history = _ref.history;
  var classes = useStyles();

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var _useState = useState({
    kits: true,
    orders: true
  }),
      _useState2 = _slicedToArray(_useState, 2),
      collapse = _useState2[0],
      setCollapse = _useState2[1];

  var _useState3 = useState(open ? -20 : drawerWidth - 20),
      _useState4 = _slicedToArray(_useState3, 2),
      drawerPos = _useState4[0],
      setDrawerPos = _useState4[1];

  var handleDrawerChange = function handleDrawerChange() {
    setOpen(!open);
    setDrawerPos(open ? -20 : drawerWidth - 20);
  };

  var inCatalog = location.href.toLowerCase().includes(window.appConfig.catalogUrl);

  var onClickLink = function onClickLink(url) {
    inCatalog ? window.location.assign(window.appConfig.ordersUrl + url) : history.push(url);
  };

  var unverifiedEmailPage = location.pathname.toLowerCase() === "/email-not-verified";
  return hide || unverifiedEmailPage ? React.createElement("div", null) : React.createElement("div", null, React.createElement(IconButton // TODO: Attach to sidemenu
  // currently hidden
  , {
    // currently hidden
    color: "inherit",
    "aria-label": "open drawer",
    onClick: handleDrawerChange,
    className: classes.expandButton,
    style: {
      left: "".concat(drawerPos, "px")
    }
  }, open ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null)), React.createElement("div", {
    className: classes.root
  }, React.createElement(Drawer, {
    className: classes.drawer,
    variant: "persistent",
    anchor: "left",
    open: open,
    classes: {
      paper: classes.drawerPaper
    }
  }, React.createElement("div", {
    className: inCatalog ? classes.catalogDrawerHeader : classes.drawerHeader
  }), React.createElement(List, {
    disablePadding: true
  }, React.createElement(ListItem, {
    id: "headerMenu-dashboard-test-catalog",
    button: true,
    onClick: function onClick() {
      return window.location.assign(window.appConfig.catalogUrl);
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(HomeOutlinedIcon, null)), React.createElement(ListItemText, null, "Test Catalog")), React.createElement(ListItem, {
    id: "headerMenu-dashboard",
    button: true,
    onClick: function onClick() {
      return onClickLink("/");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(DashboardOutlinedIcon, null)), React.createElement(ListItemText, null, "Dashboard")), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        orders: !collapse.orders
      }));
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentOutlinedIcon, null)), React.createElement(ListItemText, {
    primary: "Orders"
  }), collapse.orders ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.orders,
    timeout: "auto"
  }, React.createElement(ListItem, {
    id: "headerMenu-dashboard-search",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(FindInPageOutlinedIcon, null)), React.createElement(ListItemText, null, " Search")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-en-route",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=en-route&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(LocalShippingOutlinedIcon, null)), React.createElement(ListItemText, null, "En Route")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-accessioned",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=accessioned&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentTurnedInOutlinedIcon, null)), React.createElement(ListItemText, null, "Accessioned")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-in-process",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=in-process&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement("img", {
    alt: "in-process",
    className: classes.iconImage,
    width: 24,
    height: 24,
    src: "/images/in-process-icon.svg"
  })), React.createElement(ListItemText, null, "In Process")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-completed",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=reported&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentReturnOutlinedIcon, null)), React.createElement(ListItemText, null, "Reported")), React.createElement(ListItem, {
    id: "headerMenu-dashboard-pending-submission",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/orders?status=pending-submission&orderBy=orderDate&page=1");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(AssignmentLateOutlinedIcon, null)), React.createElement(ListItemText, null, "Pending Submission"))), React.createElement(ListItem, {
    button: true,
    onClick: function onClick() {
      return setCollapse(Object.assign(Object.assign({}, collapse), {
        kits: !collapse.kits
      }));
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(CardTravelOutlinedIcon, null)), React.createElement(ListItemText, {
    primary: "Kits"
  }), collapse.kits ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement(Collapse, {
    in: collapse.kits,
    timeout: "auto",
    unmountOnExit: true
  }, React.createElement(List, {
    disablePadding: true
  }, React.createElement(ListItem, {
    id: "headerMenu-dashboard-kits",
    className: classes.nestedItem,
    button: true,
    onClick: function onClick() {
      return onClickLink("/kits");
    }
  }, React.createElement(ListItemIcon, {
    className: classes.listIcon
  }, React.createElement(SearchOutlinedIcon, null)), React.createElement(ListItemText, null, "Search"))))))));
};

export default withRouter(Sidebar);