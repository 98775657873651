import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { PrimaryButton, SecondaryButton } from './buttons';

var DisplayDialog = function DisplayDialog(_ref) {
  var dialogBody = _ref.dialogBody,
      dialogTitle = _ref.dialogTitle,
      openDialog = _ref.openDialog,
      onClose = _ref.onClose;

  var handleClose = function handleClose(confirm) {
    return function () {
      return onClose && onClose(confirm || false);
    };
  };

  return React.createElement("div", null, React.createElement(Dialog, {
    open: openDialog ? openDialog : false,
    onClose: handleClose(),
    "aria-labelledby": "alert-dialog-title",
    "aria-describedby": "alert-dialog-description"
  }, React.createElement(DialogTitle, {
    id: "alert-dialog-title"
  }, dialogTitle), React.createElement(DialogContent, null, React.createElement(DialogContentText, {
    id: "alert-dialog-description"
  }, dialogBody)), React.createElement(DialogActions, null, onClose && React.createElement(PrimaryButton, {
    onClick: handleClose(true),
    color: "primary"
  }, "Confirm"), React.createElement(SecondaryButton, {
    onClick: handleClose(false),
    color: "secondary"
  }, "Close"))));
};

export default DisplayDialog;