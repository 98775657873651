import React from "react";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../theme";
import { Grid } from "@material-ui/core";
import HorizontalRibbon from "./HorizontalRibbon";
var useStyles = makeStyles({
  copyright: {
    color: colors.white,
    fontFamily: "'Roboto Condensed'",
    fontSize: "14px",
    width: "fit-content"
  },
  contactUs: {
    color: colors.white,
    fontFamily: "'Roboto Condensed'",
    fontSize: "14px",
    marginLeft: "auto"
  },
  footer: {
    height: "3em",
    width: "100%",
    backgroundColor: colors.deepSeaBlue,
    overflow: "hidden",
    color: "#284391",
    paddingTop: "1em",
    zIndex: 500000
  },
  footerTextContainer: {
    flexGrow: 1,
    display: "flex",
    padding: "0 20px"
  }
});

var Footer = function Footer() {
  var classes = useStyles();
  return React.createElement(Grid, {
    container: true,
    className: classes.footer
  }, React.createElement(HorizontalRibbon, {
    style: {
      backgroundColor: colors.deepSeaBlue
    }
  }, React.createElement("div", {
    className: classes.footerTextContainer
  }, React.createElement("span", {
    className: classes.copyright
  }, "\xA9 ", new Date().getFullYear(), " Baylor Genetics"), React.createElement("a", {
    className: classes.contactUs,
    href: "https://www.baylorgenetics.com/contact/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Contact Us"))));
};

export default Footer;