import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "../../theme";
var useStyles = makeStyles({
  selectedButton: {
    fontFamily: "Roboto Condensed",
    fontSize: fonts.sizes.xxs,
    background: colors.mediumBlue,
    borderRadius: "0.5em",
    color: "white",
    padding: "0 0.5em",
    height: "1.5em",
    lineHeight: "1.3em",
    margin: "0.5em 0.5em",
    "&:disabled": {
      color: "white"
    },
    "& span": {
      whiteSpace: "pre"
    }
  },
  button: {
    fontFamily: "Roboto Condensed",
    fontSize: fonts.sizes.xxs,
    border: "2px solid ".concat(colors.mediumBlue),
    background: "transparent",
    borderRadius: "0.5em",
    color: colors.mediumBlue,
    padding: "0 0.5em",
    height: "1.5em",
    lineHeight: "1.3em",
    margin: "0.5em 0.5em",
    "& span": {
      whiteSpace: "pre"
    }
  }
});

var GeneButton = function GeneButton(props) {
  var classes = useStyles();
  return React.createElement(Button, Object.assign({
    className: props.selected ? classes.selectedButton : classes.button
  }, props));
};

export default GeneButton;