import React, { Component } from "react";
import "./App.css";
import Routes from "./Routes";
import { MuiThemeProvider } from "@material-ui/core";
import { themes } from "@bg/shared/lib/theme";
import { SnackbarProvider } from "notistack";
import { LookupProvider } from "@bg/shared/lib/components/pickers";
import {
	withAITracking,
	AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

class App extends Component {
	public render() {
		return (
			<AppInsightsContext.Provider value={reactPlugin}>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
				>
					<LookupProvider>
						<MuiThemeProvider theme={themes.appTheme}>
							<div className="App">
								<Routes />
							</div>
						</MuiThemeProvider>
					</LookupProvider>
				</SnackbarProvider>
			</AppInsightsContext.Provider>
		);
	}
}

export default withAITracking(reactPlugin, App);
