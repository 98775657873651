import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "../../theme";
var useStyles = makeStyles({
  button: {
    fontFamily: "Roboto Condensed",
    fontSize: fonts.sizes.xxs,
    border: "2px solid ".concat(colors.white),
    background: "transparent",
    borderRadius: "1.5em",
    color: "white",
    padding: "0 0.5em",
    height: "1.5em",
    lineHeight: "1.3em",
    marginTop: "0.2em"
  }
});

var LoginButton = function LoginButton(props) {
  var classes = useStyles();
  return React.createElement(Button, Object.assign({
    className: classes.button
  }, props));
};

export default LoginButton;