import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useLookups } from "./LookupProvider";

var getCollection = function getCollection(type) {
  switch (type) {
    case "Country":
      return "countries";

    case "State":
      return "states";

    case "Ethnicity":
      return "ethnicities";

    case "Document Type":
      return "documentTypes";

    default:
      throw new Error("Type not supported");
  }
};

var LookupPicker = function LookupPicker(props) {
  return React.createElement(FormControl, {
    fullWidth: props.fullWidth
  }, React.createElement(InputLabel, null, "".concat(props.type, " ").concat(props.required ? "*" : "")), React.createElement(Select, Object.assign({
    native: true
  }, props), React.createElement("option", null), useLookups()[getCollection(props.type)].map(function (item, index) {
    return React.createElement("option", {
      key: index,
      value: item.id
    }, item.name);
  })));
};

export default LookupPicker;