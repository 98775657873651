import React from "react";
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, Divider, Chip, Typography } from "@material-ui/core";
import useStyles from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loading from "../Loading";

var HeaderOverlay = function HeaderOverlay(_ref) {
  var data = _ref.data;
  var classes = useStyles();

  if (data === undefined) {
    return React.createElement(Grid, {
      container: true,
      className: classes.loading
    }, React.createElement(Loading, null));
  }

  var isAlertExist = Boolean(data.otherLate + data.otherMissingInfo + data.wgsLate + data.wgsMissingInfo > 0);
  return React.createElement(Grid, {
    container: true,
    className: classes.container
  }, React.createElement(Grid, {
    item: true,
    className: classes.header,
    xs: 12
  }, "Notifications"), React.createElement(Grid, {
    item: true,
    xs: 12,
    className: classes.accordionContainer
  }, !isAlertExist ? React.createElement(Typography, {
    align: "center"
  }, "No item to show") : React.createElement(React.Fragment, null, data.otherLate + data.otherMissingInfo > 0 && React.createElement(ExpansionPanel, {
    defaultExpanded: true,
    className: classes.accordion
  }, React.createElement(ExpansionPanelSummary, {
    expandIcon: React.createElement(ExpandMoreIcon, null),
    className: classes.accordionSummary
  }, React.createElement("p", null, "Other"), React.createElement(Chip, {
    size: "small",
    label: data.otherLate + data.otherMissingInfo
  })), React.createElement(ExpansionPanelDetails, {
    className: classes.accordionDetails
  }, React.createElement(List, {
    className: classes.list
  }, React.createElement(Divider, null), React.createElement(ListItem, null, React.createElement("span", null, "Delayed Samples"), React.createElement("span", null, data.otherLate)), React.createElement(Divider, null), React.createElement(ListItem, null, React.createElement("span", null, "Missing Information"), React.createElement("span", null, data.otherMissingInfo))))), data.wgsLate + data.wgsMissingInfo > 0 && React.createElement(ExpansionPanel, {
    defaultExpanded: true,
    className: classes.accordion
  }, React.createElement(ExpansionPanelSummary, {
    expandIcon: React.createElement(ExpandMoreIcon, null),
    className: classes.accordionSummary
  }, React.createElement("p", null, "WGS"), React.createElement(Chip, {
    size: "small",
    label: data.wgsLate + data.wgsMissingInfo
  })), React.createElement(ExpansionPanelDetails, {
    className: classes.accordionDetails
  }, React.createElement(List, {
    className: classes.list
  }, React.createElement(Divider, null), React.createElement(ListItem, null, React.createElement("span", null, "Late"), React.createElement("span", null, data.wgsLate)), React.createElement(Divider, null), React.createElement(ListItem, null, React.createElement("span", null, "Missing"), React.createElement("span", null, data.wgsMissingInfo))))))));
};

export default HeaderOverlay;