import React from "react";
import * as icons from "@material-ui/icons";
import { fonts, colors } from "../theme";
import { withStyles } from "@material-ui/core";
import { DefaultLayout } from "../components";

var ErrorPage = function ErrorPage(_ref) {
  var classes = _ref.classes;
  return React.createElement(DefaultLayout, null, React.createElement("div", {
    style: {
      textAlign: "center"
    },
    className: classes.font
  }, React.createElement(icons.ErrorOutline, {
    style: {
      fontSize: "10em"
    },
    htmlColor: colors.lightBrown
  }), React.createElement("br", null), React.createElement("div", {
    style: {
      color: colors.mediumBrown,
      fontWeight: 100,
      fontSize: fonts.sizes.xxl,
      marginBottom: "2em"
    }
  }, "There was an error"), React.createElement("div", {
    style: {
      color: colors.deepSeaBlue,
      fontWeight: 400,
      fontSize: fonts.sizes.sm
    }
  }, "There was an issue retrieving your information. Please try again and if you are receiving the same error message please contact", " ", React.createElement("a", {
    href: "mailto: help@baylorgenetics.com",
    style: {
      color: colors.deepSeaBlue,
      fontWeight: "bold"
    }
  }, "Baylor\xA0Genetics"), " ", "for assistance.")));
};

export default withStyles(fonts.fontStyles)(ErrorPage);