import { Slide } from '@material-ui/core';
import React from 'react';
import { colors } from '../theme';

var StepSlide = function StepSlide(_ref) {
  var children = _ref.children,
      step = _ref.step,
      activeStep = _ref.activeStep,
      useNew = _ref.useNew;

  if (useNew) {
    return activeStep === step ? React.createElement(Slide, {
      direction: "left",
      in: activeStep === step,
      mountOnEnter: true,
      unmountOnExit: true,
      timeout: 600
    }, React.createElement(React.Fragment, null, children)) : React.createElement(Slide, {
      direction: "right",
      in: activeStep === step,
      mountOnEnter: true,
      unmountOnExit: true,
      timeout: 300
    }, React.createElement(React.Fragment, null, children));
  }

  return React.createElement(Slide, {
    direction: "right",
    in: activeStep === step,
    mountOnEnter: true,
    unmountOnExit: true
  }, React.createElement("div", {
    style: {
      padding: "1em",
      backgroundColor: colors.grey
    }
  }, children));
};

export default StepSlide;