import { Button, InputAdornment, InputBase, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";
import { colors, fonts } from "../theme";
var useStyles = makeStyles({
  searchInput: {
    fontFamily: "Roboto",
    fontSize: fonts.sizes.xs,
    fontWeight: 300,
    color: colors.mediumBrown,
    backgroundColor: colors.white,
    padding: "0.4em 1em",
    display: "inline-block",
    borderRadius: "18px"
  },
  searchButton: {
    maxWidth: "35px",
    maxHeight: "35px",
    minWidth: "35px",
    minHeight: "35px",
    fontSize: fonts.sizes.xs,
    backgroundColor: colors.powderBlue,
    color: colors.white,
    verticalAlign: "middle",
    boxShadow: "none",
    borderRadius: "50%"
  },
  searchButtonAdornment: {}
});

var SearchField = function SearchField(_ref) {
  var searchText = _ref.searchText,
      placeholderText = _ref.placeholderText,
      handleChange = _ref.handleChange,
      handleSubmit = _ref.handleSubmit;
  var classes = useStyles();
  return React.createElement(InputBase, {
    classes: {
      input: classes.searchInput
    },
    placeholder: placeholderText,
    id: "search-text",
    value: searchText,
    onChange: handleChange,
    fullWidth: true,
    onKeyDown: function onKeyDown(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSubmit();
      }
    },
    endAdornment: React.createElement(InputAdornment, {
      position: "end",
      className: classes.searchButtonAdornment
    }, React.createElement("div", {
      style: {
        display: "inline-block"
      }
    }, React.createElement(Button, {
      id: "search",
      "aria-label": "Search",
      className: classes.searchButton,
      onClick: handleSubmit
    }, React.createElement(Search, null))))
  });
};

export default SearchField;