import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { createStyles, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { colors, fonts } from "../theme";
import { darken } from "@material-ui/core/styles";
import clsx from "clsx";
import { RemoveCircleOutline, AddCircleOutline } from "@material-ui/icons";
import { formatId } from "./formatters";

var styles = function styles(theme) {
  return createStyles({
    button: {
      backgroundColor: colors.lightBrown,
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
      color: colors.mediumBrown,
      "&:hover": {
        background: darken(colors.lightBrown, 0.1)
      }
    },
    buttonLabel: Object.assign(Object.assign({}, fonts.fontStyles.panelFont), {
      display: "block",
      padding: "0 0.5em",
      textAlign: "left"
    }),
    container: {
      width: "100%"
    },
    panel: {
      overflow: "hidden",
      padding: "0.75em 3em"
    },
    expanded: {
      transition: "transform 150ms ease",
      transformOrigin: "top",
      transform: "scaleY(1)"
    },
    hidden: {
      maxHeight: 0,
      transition: "transform 150ms ease",
      transformOrigin: "top",
      transform: "scaleY(0)"
    },
    icon: {
      color: colors.white,
      marginRight: "0.25em",
      verticalAlign: "text-bottom"
    },
    error: {
      border: "thin solid ".concat(colors.candyRed)
    }
  });
};

var PrimaryExpansionPanel = forwardRef(function (props, ref) {
  var classes = props.classes,
      children = props.children,
      defaultExpanded = props.defaultExpanded,
      title = props.title,
      id = props.id,
      _props$error = props.error,
      error = _props$error === void 0 ? false : _props$error;

  var _useState = useState(defaultExpanded !== undefined ? defaultExpanded : false),
      _useState2 = _slicedToArray(_useState, 2),
      expanded = _useState2[0],
      setExpanded = _useState2[1];

  useImperativeHandle(ref, function () {
    return {
      expand: function expand() {
        setExpanded(true);
      }
    };
  });
  return React.createElement("div", {
    className: classes.container
  }, React.createElement(Button, {
    id: id ? id : formatId(title),
    className: error ? classes.error : "",
    classes: {
      root: classes.button,
      label: classes.buttonLabel
    },
    fullWidth: true,
    onClick: function onClick() {
      return setExpanded(!expanded);
    }
  }, React.createElement("span", null, expanded ? React.createElement(RemoveCircleOutline, {
    className: classes.icon
  }) : React.createElement(AddCircleOutline, {
    className: classes.icon
  })), React.createElement("span", null, title)), React.createElement("div", {
    className: clsx(classes.panel, expanded ? classes.expanded : classes.hidden)
  }, expanded && children));
});
export default withStyles(styles)(PrimaryExpansionPanel);