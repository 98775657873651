import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useLookups } from "./LookupProvider";

var EthnicityPicker = function EthnicityPicker(props) {
  return React.createElement(FormControl, {
    fullWidth: props.fullWidth
  }, React.createElement(InputLabel, null, props.required ? "Ethnicity *" : "Ethnicity"), React.createElement(Select, Object.assign({
    native: true
  }, props), React.createElement("option", null), useLookups().ethnicities.map(function (ethnicity, index) {
    return React.createElement("option", {
      key: index,
      value: ethnicity.id
    }, ethnicity.name);
  })));
};

export default EthnicityPicker;