export var lightBrown = "#DBD3C6";
export var salmonPink = "#ECB29F";
export var mediumBrown = "#8B7769";
export var yellowGold = "#EBAA41";
export var candyRed = "#EE4137";
export var powderBlue = "#28A8E0";
export var skyBlue = "#C0EBFE";
export var deepSeaBlue = "#160E44";
export var royalBlue = "#284391";
export var mediumBlue = "#1D76BB";
export var tealBlueGreen = "#48AD96";
export var white = "#FFFFFF";
export var black = "#000000";
export var grey = "#e6e6e6";
export var darkGrey = "#cccccc";
export var lightGrey = "#666666";
export var mediumGray = "#757575";
export var seaShell = "#F1F1F1";
export var silver = "#C0C0C0";
export var darkSilver = "#808080";
export var darkRed = "#C00000";
export var charcoal = "#585858";
export var santaCharcoal = "#8E8E8E";
export var sleepyGrey = "#6D6D6D";
export var blueLink = "#106cc8";
export var midGrey = "#6A6A8A";
export var darkGreyBrown = "#6F6F6F";
export var darkBrown = "#767676";
export var anotherGrey = "#DADADA";
export var getRandomColor = function getRandomColor(seed) {
  var colorArray = [lightBrown, salmonPink, mediumBrown, yellowGold, candyRed, powderBlue, skyBlue, deepSeaBlue, royalBlue, mediumBlue, tealBlueGreen, white, grey, darkGrey, lightGrey, mediumGray, seaShell];
  var index;

  if (seed !== undefined && !isNaN(seed)) {
    index = seed % colorArray.length;
  } else {
    index = Math.floor(Math.random() * colorArray.length);
  }

  return colorArray[index];
};
export var getContrastColor = function getContrastColor(hexcolor) {
  if (hexcolor === undefined || hexcolor.length < 6) {
    return white;
  } // from https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
  // If a leading # is provided, remove it


  if (hexcolor.toString().slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  } // Convert to RGB value


  var r = parseInt(hexcolor.slice(0, 2), 16);
  var g = parseInt(hexcolor.slice(2, 4), 16);
  var b = parseInt(hexcolor.slice(4, 6), 16); // Get YIQ ratio

  var yiq = (r * 299 + g * 587 + b * 114) / 1000; // Check contrast

  return yiq >= 128 ? black : white;
};