import * as color from "./colors";
import { createStyles } from "@material-ui/core";
export var fonts = "Roboto"; // Comments are Simulated sizes from mockup

export var sizes = {
  xxxs: "0.8em",
  xxs: "1em",
  xs: "1.1em",
  sm: "1.2em",
  md: "1.3em",
  lg: "1.4em",
  xl: "1.5em",
  xxl: "2.4em" // 36pt

};
export var fontStyles = createStyles({
  font: {
    fontFamily: fonts
  },
  breadcrumbs: {
    color: color.deepSeaBlue,
    fontSize: sizes.xxs
  },
  breadcrumbSlash: {
    color: color.powderBlue,
    fontSize: sizes.xxs
  },
  heading: {
    fontFamily: fonts,
    fontWeight: 400,
    color: color.powderBlue,
    fontSize: "18pt"
  },
  subHeading: {
    fontFamily: fonts,
    fontWeight: 500,
    color: color.deepSeaBlue
  },
  addlInfo: {
    fontFamily: fonts,
    fontWeight: 300,
    color: color.lightBrown
  },
  minFont: {
    fontFamily: fonts,
    fontWeight: 300,
    color: color.white
  },
  panelFont: {
    fontFamily: fonts,
    fontWeight: 400,
    fontSize: "14pt"
  },
  subPanelFont: {
    fontFamily: fonts,
    fontWeight: 400,
    fontSize: "12pt"
  }
});