import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

var HorizontalRibbon = function HorizontalRibbon(_ref) {
  var children = _ref.children,
      className = _ref.className,
      style = _ref.style;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      ribbonStyle = _useState2[0],
      setRibbonStyle = _useState2[1];

  useEffect(function () {
    var handleResize = function handleResize() {
      var screenWidth = window.innerWidth;
      var customStyle = Object.assign({
        padding: screenWidth < 500 ? "0 0" : "0 2em",
        display: "flex",
        backgroundColor: "inherit"
      }, style);
      setRibbonStyle(customStyle);
    };

    handleResize();
  }, [window.innerWidth]);
  return React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    xs: false,
    md: true
  }), React.createElement(Grid, {
    item: true,
    xs: 12,
    md: 10,
    lg: 8,
    style: ribbonStyle
  }, children), React.createElement(Grid, {
    item: true,
    xs: false,
    md: true
  }));
};

export default HorizontalRibbon;