import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
var useStyles = makeStyles({
  info: {
    fontSize: "1rem",
    lineHeight: "1.3em"
  }
});

var Info = function Info(props) {
  var classes = useStyles();
  return React.createElement(Typography, Object.assign({}, props, {
    className: clsx(classes.info, props.className)
  }), props.children);
};

export default Info;