import React from "react";
import { GetStateName } from "../pickers/StatePicker";

var FormatAddress = function FormatAddress(patientAddress, paymentAddress, singleLine) {
  var address = "";
  var address2 = "";
  var city = "";
  var state = "";
  var zipCode = "";

  if (patientAddress) {
    address = patientAddress.addressLine1 || "";
    address2 = patientAddress.addressLine2 || "";
    city = patientAddress.city || "";
    state = GetStateName(patientAddress.stateId);
    zipCode = patientAddress.postalCode || "";
  } else if (paymentAddress && !patientAddress) {
    address = paymentAddress.insuredAddress || "";
    city = paymentAddress.insuredCity || "";
    state = GetStateName(paymentAddress.insuredStateId);
    zipCode = paymentAddress.insuredZip || "";
  }

  var singleLineAddress = "".concat(address ? address + "," : "", " ").concat(address2 ? address2 + "," : "", " ").concat(city ? city + "," : "", " ").concat(state, " ").concat(zipCode);
  var multiLineAddress = React.createElement(React.Fragment, null, address && React.createElement(React.Fragment, null, address, " ", React.createElement("br", null)), address2 && React.createElement(React.Fragment, null, address2, " ", React.createElement("br", null)), "".concat(city ? city + "," : "", " ").concat(state, " ").concat(zipCode));
  return singleLine ? singleLineAddress : multiLineAddress;
};

export default FormatAddress;