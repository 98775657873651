import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { Grid, Popover } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { colors } from "../../../theme";
import SubMenuItems, { aboutUsItems, newsAndUpdatesItems, providersOrderingItems, providersResourcesItems, providersSupportItems, testComprehensiveItems, testReproductuveItems, testSpecializedModalityItems } from "./CatalogHeaderHelper";

var styles = function styles() {
  return createStyles({
    menuButton: {
      position: "relative",
      height: "100%",
      "&:hover, &$focusVisible": {
        background: colors.mediumBrown,
        color: colors.white
      },
      "& $itemTitle": {},
      "& $linkButton": {}
    },
    focusVisible: {},
    linkButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    itemTitle: {
      position: "relative",
      textTransform: "uppercase"
    },
    subMenuDiv: {
      background: colors.deepSeaBlue,
      justifyContent: "left",
      textAlign: "left",
      pointerEvents: "auto"
    },
    subMenuHeader: {
      flexDirection: "column",
      textTransform: "uppercase",
      padding: "0px 0px 0px 10px",
      background: "inherit",
      color: colors.powderBlue,
      textAlign: "left",
      justifyContent: "left"
    },
    subMenuHeaderButton: {
      background: colors.deepSeaBlue,
      color: colors.white,
      height: 40,
      textAlign: "left",
      justifyContent: "left"
    },
    subMenuLinkButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 10,
      bottom: 15,
      display: "flex",
      alignItems: "left",
      justifyContent: "left"
    }
  });
};

var menuItems = [{
  url: "https://www.baylorgenetics.com/partnerships/",
  title: "Partnerships",
  width: "150px"
}, {
  url: "#",
  title: "Providers",
  width: "122px"
}, {
  url: "#",
  title: "Tests",
  width: "86px"
}, {
  url: "#",
  title: "About Us",
  width: "113px"
}, {
  url: "#",
  title: "News & Updates",
  width: "168px"
}];

var MarketingMenu = function MarketingMenu(_ref) {
  var classes = _ref.classes;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      selectedMenu = _useState4[0],
      setSelectedMenu = _useState4[1];

  var handleAnchorSet = function handleAnchorSet(name) {
    return function (event) {
      setSelectedMenu(name);
      setAnchorEl(event.currentTarget);
    };
  };

  var handleAnchorClose = function handleAnchorClose() {
    setSelectedMenu("");
    setAnchorEl(null);
  };

  var subMenuHeader = function subMenuHeader(title, url, width) {
    return React.createElement(ButtonBase, {
      focusRipple: true,
      key: "".concat(title.replace(" ", "-")),
      className: classes.subMenuHeaderButton,
      focusVisibleClassName: classes.focusVisible,
      disabled: !url,
      style: {
        width: width
      }
    }, url ? React.createElement("a", {
      className: classes.subMenuLinkButton,
      href: url
    }, React.createElement(Typography, {
      component: "span",
      variant: "subtitle1",
      color: "inherit",
      className: classes.subMenuHeader,
      key: "".concat(title.replace(" ", "-"), "-title"),
      align: "left",
      style: {
        width: width
      }
    }, title)) : React.createElement(Typography, {
      component: "span",
      variant: "subtitle1",
      color: "inherit",
      className: classes.subMenuHeader,
      key: "".concat(title.replace(" ", "-"), "-title"),
      align: "left",
      style: {
        width: width
      }
    }, title));
  };

  var providersMenu = function providersMenu() {
    var sunMenuWidth = "900px"; //three columns menu

    var subMenuItemWidth = "300px";
    return React.createElement("div", {
      className: classes.subMenuDiv,
      style: {
        width: sunMenuWidth
      }
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 4
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, subMenuHeader("Ordering", "#", subMenuItemWidth), React.createElement(SubMenuItems, {
      items: providersOrderingItems,
      width: subMenuItemWidth
    })))), React.createElement(Grid, {
      item: true,
      xs: 4
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, subMenuHeader("Support", "", subMenuItemWidth), React.createElement(SubMenuItems, {
      items: providersSupportItems,
      width: subMenuItemWidth
    })))), React.createElement(Grid, {
      item: true,
      xs: 4
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, subMenuHeader("Resources", "", "300px"), React.createElement(SubMenuItems, {
      items: providersResourcesItems,
      width: "300px"
    }))))));
  };

  var testsMenu = function testsMenu() {
    var sunMenuWidth = "650px"; //two columns menu

    var subMenuItemWidth = "350px";
    return React.createElement("div", {
      className: classes.subMenuDiv,
      style: {
        width: sunMenuWidth
      }
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 6
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, subMenuHeader("Comprehensive", "#", subMenuItemWidth), React.createElement(SubMenuItems, {
      items: testComprehensiveItems,
      width: subMenuItemWidth
    }), subMenuHeader("Specialized Modality", "#", subMenuItemWidth), React.createElement(SubMenuItems, {
      items: testSpecializedModalityItems,
      width: subMenuItemWidth
    })))), React.createElement(Grid, {
      item: true,
      xs: 6
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, subMenuHeader("Reproductive", "#", subMenuItemWidth), React.createElement(SubMenuItems, {
      items: testReproductuveItems,
      width: subMenuItemWidth
    }))))));
  };

  var aboutUsMenu = function aboutUsMenu() {
    var sunMenuWidth = "150px"; //one column menu

    var subMenuItemWidth = "150px";
    return React.createElement("div", {
      className: classes.subMenuDiv,
      style: {
        width: sunMenuWidth
      }
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 6
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, React.createElement(SubMenuItems, {
      items: aboutUsItems,
      width: subMenuItemWidth
    }))))));
  };

  var newsAndUpdatesMenu = function newsAndUpdatesMenu() {
    var sunMenuWidth = "200px"; //one column menu

    return React.createElement("div", {
      className: classes.subMenuDiv,
      style: {
        width: sunMenuWidth
      }
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 6
    }, React.createElement(Grid, {
      container: true,
      spacing: 0
    }, React.createElement(Grid, {
      item: true,
      xs: 12
    }, React.createElement(SubMenuItems, {
      items: newsAndUpdatesItems,
      width: sunMenuWidth
    }))))));
  };

  var getSubMenu = function getSubMenu(menuTitle) {
    switch (menuTitle.toLowerCase()) {
      case "providers":
        return providersMenu();

      case "tests":
        return testsMenu();

      case "about us":
        return aboutUsMenu();

      case "news & updates":
        return newsAndUpdatesMenu();

      default:
        return null;
    }
  };

  return React.createElement("div", {
    onMouseLeave: handleAnchorClose
  }, menuItems.map(function (item) {
    return React.createElement(ButtonBase, {
      focusRipple: true,
      key: "".concat(item.title.replace(" ", "-"), "-menu"),
      className: classes.menuButton,
      focusVisibleClassName: classes.focusVisible,
      onMouseOver: handleAnchorSet(item.title),
      style: {
        width: item.width,
        height: 35,
        background: selectedMenu === item.title ? colors.mediumBrown : colors.white,
        color: selectedMenu === item.title ? colors.white : colors.deepSeaBlue
      }
    }, React.createElement("a", {
      className: classes.linkButton,
      href: item.url
    }, React.createElement(Typography, {
      component: "span",
      variant: "subtitle1",
      color: "inherit",
      className: classes.itemTitle,
      key: "".concat(item.title.replace(" ", "-"), "-menu-title")
    }, item.title)));
  }), getSubMenu(selectedMenu) && React.createElement(Popover, {
    id: "sub-menu",
    key: "sub-menu",
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    onClose: handleAnchorClose,
    style: {
      pointerEvents: "none"
    },
    hideBackdrop: true,
    disableRestoreFocus: true,
    disableAutoFocus: true,
    PaperProps: {
      onMouseLeave: handleAnchorClose
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    }
  }, getSubMenu(selectedMenu)));
};

export default withStyles(styles)(MarketingMenu);