export var GetOrderPhysician = function GetOrderPhysician(physicianDetails) {
  var fax = physicianDetails.phoneNumbers && physicianDetails.phoneNumbers.find(function (x) {
    return x.type === "Fax";
  });
  var phone = physicianDetails.phoneNumbers && physicianDetails.phoneNumbers.find(function (x) {
    return x.type !== "Fax";
  });
  var addresses = GetPatientAddressesFromAddresses(physicianDetails.addresses);
  var physician = {
    addresses: addresses,
    emails: physicianDetails.emails,
    fax: fax ? fax.phoneNumber : undefined,
    firstName: physicianDetails.firstName,
    id: physicianDetails.id,
    lastName: physicianDetails.lastName,
    npi: physicianDetails.npi,
    phone: phone ? phone.phoneNumber : undefined,
    physicianId: physicianDetails.id
  };
  return physician;
};

var GetPatientAddressesFromAddresses = function GetPatientAddressesFromAddresses(addresses) {
  var patientAddresses = [];

  if (addresses) {
    addresses.forEach(function (address) {
      var patient = {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        postalCode: address.postalCode,
        state: address.state ? address.state : address.stateName,
        stateId: address.stateId,
        type: address.type
      };
      patientAddresses.push(patient);
    });
  }

  return patientAddresses;
};

var INSTITUTION_CODES = "allInstitutionCodes";
var INSTITUTION_CODE_TO_SEARCH = "institutionCodeToSearch";
export var GetStoredInstitutionCodes = function GetStoredInstitutionCodes() {
  var storedCodes = sessionStorage.getItem(INSTITUTION_CODES);

  if (storedCodes) {
    return storedCodes.split(",");
  }

  return [];
};
export var GetInstitutionCodeToSearch = function GetInstitutionCodeToSearch() {
  return sessionStorage.getItem(INSTITUTION_CODE_TO_SEARCH);
};
export var SetInstitutionCodeToSearch = function SetInstitutionCodeToSearch(code) {
  sessionStorage.setItem(INSTITUTION_CODE_TO_SEARCH, code);
};