import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { colors } from "../theme";
var useStyles = makeStyles(createStyles({
  caretSpacing: {
    margin: "0 1rem"
  },
  slashes: {
    color: colors.powderBlue,
    margin: "0 0.25em"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "12px",
    color: colors.deepSeaBlue,
    margin: "1.5em 0",
    width: "100%",
    textTransform: "uppercase"
  }
}));

var OrderBreadcrumb = function OrderBreadcrumb(_ref) {
  var breadcrumbTitles = _ref.breadcrumbTitles;
  var classes = useStyles(); // const isEmptyOrUndefined = (x: any): boolean => {
  //     return x === undefined || x === null || x === "";
  // };

  return React.createElement("div", {
    className: classes.title
  }, React.createElement("span", null, "Orders"), breadcrumbTitles.map(function (bc) {
    return bc && React.createElement(React.Fragment, {
      key: bc
    }, React.createElement("span", {
      className: classes.caretSpacing
    }, ">"), React.createElement("span", null, bc.replace("-", " ")));
  }));
};

export default OrderBreadcrumb;