export var SampleTypesWithExtraInfo;

(function (SampleTypesWithExtraInfo) {
  SampleTypesWithExtraInfo["CVS"] = "CVS";
  SampleTypesWithExtraInfo["AmnioticFluid"] = "Amniotic Fluid";
  SampleTypesWithExtraInfo["FetalBlood"] = "Fetal Blood";
  SampleTypesWithExtraInfo["FreshTissue"] = "Fresh Tissue";
  SampleTypesWithExtraInfo["FFPESlides"] = "FFPE Slides";
  SampleTypesWithExtraInfo["FFPEBlock"] = "FFPE Blocks";
  SampleTypesWithExtraInfo["FFPETumorBlock"] = "FFPE Tumor Block";
  SampleTypesWithExtraInfo["BloodInStreckTube"] = "Blood in Streck Tube";
})(SampleTypesWithExtraInfo || (SampleTypesWithExtraInfo = {}));

export var FreshTissueColletMethodOption;

(function (FreshTissueColletMethodOption) {
  FreshTissueColletMethodOption["POC"] = "POC";
  FreshTissueColletMethodOption["Villi"] = "Villi";
})(FreshTissueColletMethodOption || (FreshTissueColletMethodOption = {}));

export var CvsCollectMethodOption;

(function (CvsCollectMethodOption) {
  CvsCollectMethodOption["TA"] = "TA";
  CvsCollectMethodOption["TC"] = "TC";
})(CvsCollectMethodOption || (CvsCollectMethodOption = {}));

export var ImagingPerformedOption;

(function (ImagingPerformedOption) {
  ImagingPerformedOption["Ultrasound"] = "Ultrasound";
  ImagingPerformedOption["FetalEchocardiogram"] = "FetalEchocardiogram";
  ImagingPerformedOption["MRI"] = "MRI";
  ImagingPerformedOption["Other"] = "Other";
})(ImagingPerformedOption || (ImagingPerformedOption = {}));

export var FetalGenderOption;

(function (FetalGenderOption) {
  FetalGenderOption["Male"] = "Male";
  FetalGenderOption["Female"] = "Female";
  FetalGenderOption["Ambiguous"] = "Ambiguous";
  FetalGenderOption["Unknown"] = "Unknown";
})(FetalGenderOption || (FetalGenderOption = {}));

export var PrenatalTestingOptions;

(function (PrenatalTestingOptions) {
  PrenatalTestingOptions["Yes"] = "Yes";
  PrenatalTestingOptions["No"] = "No";
  PrenatalTestingOptions["Normal"] = "Normal";
  PrenatalTestingOptions["Abnormal"] = "Abnormal";
})(PrenatalTestingOptions || (PrenatalTestingOptions = {}));

export var TestingLocation;

(function (TestingLocation) {
  TestingLocation["BaylorGenetics"] = "Baylor Genetics";
  TestingLocation["AnotherLaboratory"] = "AnotherLaboratory";
})(TestingLocation || (TestingLocation = {}));

export var PaymentType;

(function (PaymentType) {
  PaymentType["SelfPay"] = "SelfPay";
  PaymentType["Intitutional"] = "Intitutional";
  PaymentType["Insurance"] = "Insurance";
})(PaymentType || (PaymentType = {}));

export var SelfPaymentOption;

(function (SelfPaymentOption) {
  SelfPaymentOption["None"] = "None";
  SelfPaymentOption["PayWithSample"] = "PayWithSample";
  SelfPaymentOption["BillToPatient"] = "BillToPatient";
})(SelfPaymentOption || (SelfPaymentOption = {}));

export var LogLevel;

(function (LogLevel) {
  LogLevel["Trace"] = "Trace";
  LogLevel["Debug"] = "Debug";
  LogLevel["Information"] = "InInformation";
  LogLevel["Warning"] = "Warning";
  LogLevel["Error"] = "Error";
  LogLevel["Critical"] = "Critical";
  LogLevel["None"] = "None";
})(LogLevel || (LogLevel = {}));

export var OrderTestStatus;

(function (OrderTestStatus) {
  OrderTestStatus["Draft"] = "Draft";
  OrderTestStatus["Active"] = "Active";
  OrderTestStatus["Completed"] = "Completed";
  OrderTestStatus["PendingCancellation"] = "PendingCancellation";
  OrderTestStatus["Cancelled"] = "Cancelled";
})(OrderTestStatus || (OrderTestStatus = {}));

export var HospitalDashboardStatus;

(function (HospitalDashboardStatus) {
  HospitalDashboardStatus["Awaiting"] = "Awaiting";
  HospitalDashboardStatus["Received"] = "Received";
  HospitalDashboardStatus["Active"] = "Active";
  HospitalDashboardStatus["Complete"] = "Complete";
})(HospitalDashboardStatus || (HospitalDashboardStatus = {}));

export var HospitalAlertTypes;

(function (HospitalAlertTypes) {
  HospitalAlertTypes["wgsLate"] = "wgsLate";
  HospitalAlertTypes["wgsMissingInfo"] = "wgsMissingInfo";
  HospitalAlertTypes["otherLate"] = "otherLate";
  HospitalAlertTypes["otherMissingInfo"] = "otherMissingInfo";
})(HospitalAlertTypes || (HospitalAlertTypes = {}));

export var DocumentSourceType;

(function (DocumentSourceType) {
  DocumentSourceType["Document_Management"] = "Document_Management";
})(DocumentSourceType || (DocumentSourceType = {}));

export var OrderSource;

(function (OrderSource) {
  OrderSource["OnlineOrderPortal"] = "OnlineOrderPortal";
})(OrderSource || (OrderSource = {}));