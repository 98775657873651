import React from "react";
import { Grid } from "@material-ui/core";
import { colors } from "../theme";

var CenterContainer = function CenterContainer(_ref) {
  var children = _ref.children,
      className = _ref.className,
      style = _ref.style;
  return React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    xs: false,
    md: true
  }), React.createElement(Grid, {
    item: true,
    xs: 12,
    md: 10,
    lg: 8,
    // xl={6}
    className: className,
    style: Object.assign({
      backgroundColor: colors.grey,
      padding: "0 2em"
    }, style)
  }, children), React.createElement(Grid, {
    item: true,
    xs: false,
    md: true
  }));
};

export default CenterContainer;