import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import { colors, fonts } from "../theme";
var useStyles = makeStyles(createStyles({
  slashes: {
    fontFamily: "Roboto",
    fontSize: fonts.sizes.sm,
    color: colors.powderBlue,
    margin: "0 0.25em"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: fonts.sizes.sm,
    color: colors.deepSeaBlue,
    marginTop: "1em",
    width: "100%",
    textAlign: "right",
    textTransform: "uppercase"
  }
}));

var Title = function Title(_ref) {
  var children = _ref.children;
  var classes = useStyles();
  return React.createElement("div", {
    className: classes.title
  }, React.createElement("span", {
    className: classes.slashes
  }, "//"), React.createElement("span", null, children));
};

export default Title;