var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { Tooltip } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

var GlobalLink = function GlobalLink(_a) {
  var toTestCatalog = _a.toTestCatalog,
      tooltipTitle = _a.tooltipTitle,
      children = _a.children,
      onClose = _a.onClose,
      props = __rest(_a, ["toTestCatalog", "tooltipTitle", "children", "onClose"]);

  var targetUrl = toTestCatalog ? window.appConfig.catalogUrl : window.appConfig.ordersUrl; // if user is in test catalog and redirect to test catalog

  if (window.location.origin === targetUrl) {
    return React.createElement(Tooltip, {
      title: tooltipTitle || ""
    }, React.createElement(Link, Object.assign({}, props, {
      onClick: function onClick() {
        return window.location.assign(props.to.toString());
      }
    }), children));
  } else {
    var url = toTestCatalog ? "".concat(window.appConfig.catalogUrl).concat(props.to.toString()) : "".concat(window.appConfig.ordersUrl).concat(props.to.toString());
    return React.createElement("a", {
      href: url,
      className: props.className,
      style: props.style
    }, children);
  }
};

export default GlobalLink;