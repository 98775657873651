import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { PrimaryButton, SecondaryButton } from "./buttons";
import { colors } from "../theme";
import { Cancel } from "@material-ui/icons";
var useStyles = makeStyles({
  buttonContainer: {
    paddingTop: ".75em",
    paddingBottom: ".75em"
  },
  floatRight: {
    float: "right"
  },
  redColor: {
    color: colors.candyRed
  }
});

var DialogContainer = function DialogContainer(_ref) {
  var dialogBody = _ref.dialogBody,
      dialogTitle = _ref.dialogTitle,
      openDialog = _ref.openDialog,
      yesText = _ref.yesText,
      noText = _ref.noText,
      _ref$maxWidth = _ref.maxWidth,
      maxWidth = _ref$maxWidth === void 0 ? "lg" : _ref$maxWidth,
      handleYesAnswer = _ref.handleYesAnswer,
      handleNoAnswer = _ref.handleNoAnswer;
  var classes = useStyles();
  return React.createElement("div", null, React.createElement(Dialog, {
    open: openDialog,
    onClose: handleNoAnswer,
    "aria-labelledby": "alert-dialog-title",
    "aria-describedby": "alert-dialog-description",
    maxWidth: maxWidth
  }, React.createElement("div", {
    className: classes.buttonContainer
  }, React.createElement(Button, {
    className: classes.floatRight,
    onClick: handleNoAnswer
  }, React.createElement(Cancel, {
    className: classes.redColor,
    fontSize: "medium"
  })), React.createElement(DialogTitle, {
    id: "alert-dialog-title"
  }, dialogTitle), React.createElement(DialogContent, null, dialogBody)), React.createElement(DialogActions, null, React.createElement(SecondaryButton, {
    onClick: handleNoAnswer,
    color: "primary"
  }, noText), React.createElement(PrimaryButton, {
    onClick: handleYesAnswer,
    color: "primary",
    autoFocus: true
  }, yesText))));
};

export default DialogContainer;