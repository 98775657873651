import _slicedToArray from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _defineProperty from "D:\\agent\\_work\\82\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { colors, fonts } from "../theme";
import { appTheme } from "../theme/themes";
var useStyles = makeStyles({
  tabContainer: {
    textAlign: "center"
  },
  appTabs: {
    backgroundColor: colors.white,
    color: colors.deepSeaBlue
  },
  tabs: {
    "& .Mui-selected": {
      color: colors.deepSeaBlue,
      fontWeight: "bold"
    }
  },
  tab: _defineProperty({
    textTransform: "unset",
    fontFamily: fonts.fonts,
    color: colors.santaCharcoal,
    fontSize: fonts.sizes.xxs,
    fontWeight: "normal"
  }, appTheme.breakpoints.down("md"), {
    fontSize: ".75em"
  }),
  tabIndication: {
    backgroundColor: colors.powderBlue
  },
  tabContent: {// paddingTop: 28,
  }
});

var TabBar = function TabBar(_ref) {
  var tabs = _ref.tabs,
      ariaLabel = _ref.ariaLabel,
      classes = _ref.classes;
  var defaultClasses = useStyles();
  var overrideClasses = classes || {};

  function TabPanel(props) {
    var children = props.children,
        value = props.value,
        index = props.index,
        other = __rest(props, ["children", "value", "index"]);

    return React.createElement("div", Object.assign({
      role: "tabpanel",
      hidden: value !== index,
      id: "simple-tabpanel-".concat(index),
      "aria-labelledby": "simple-tab-".concat(index)
    }, other), value === index && // <Box p={1}>{
    children // }</Box>
    );
  }

  function a11yProps(index) {
    return {
      id: "tab-".concat(index),
      "aria-controls": "tabpanel-".concat(index)
    };
  }

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      tabValue = _React$useState2[0],
      setTabValue = _React$useState2[1];

  var handleChange = function handleChange(_, newValue) {
    var foundTab = tabs.find(function (tab) {
      return tab.index === newValue;
    });

    if (foundTab && foundTab.runOnLoad) {
      foundTab.runOnLoad();
    }

    setTabValue(newValue);
  };

  return React.createElement("div", {
    className: overrideClasses.tabContainer || defaultClasses.tabContainer
  }, React.createElement(AppBar, {
    position: "static",
    className: defaultClasses.appTabs
  }, React.createElement(Tabs, {
    value: tabValue,
    onChange: handleChange,
    "aria-label": ariaLabel || "order tabs",
    variant: "scrollable",
    scrollButtons: "auto",
    classes: {
      flexContainer: overrideClasses.flexContainer || "",
      indicator: overrideClasses.tabIndication || defaultClasses.tabIndication
    },
    className: overrideClasses.tabs || defaultClasses.tabs
  }, tabs.map(function (tab) {
    return React.createElement(Tab, Object.assign({
      label: tab.label,
      className: overrideClasses.tab || defaultClasses.tab,
      key: tab.index
    }, a11yProps(tab.index)));
  }))), tabs.map(function (tab) {
    return React.createElement(TabPanel, {
      value: tabValue,
      index: tab.index,
      key: tab.index
    }, React.createElement("div", {
      className: overrideClasses.tabContent || defaultClasses.tabContent
    }, tab.content));
  }));
};

export default TabBar;